import React, { useState, useEffect, useContext } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Icon from 'browser/components/Icon'
// import heartIcon from 'browser/assets/icons/heart.svg'
// import fullHeartIcon from 'browser/assets/icons/full-heart.svg'
// import Button from 'browser/components/Button'
import { LangContext } from 'browser/contexts/LangContext'
import LoadedImage from 'browser/components/LoadedImage'
import { store } from 'browser/store'
// import { useHistory } from 'react-router-dom'
import roomsIcon from 'browser/assets/icons/room.svg'
import roomsIconDark from 'browser/assets/icons/room-dark.svg'
import areaIcon from 'browser/assets/icons/area.svg'
import areaIconDark from 'browser/assets/icons/area-dark.svg'
import floorIcon from 'browser/assets/icons/floor.svg'
import floorIconDark from 'browser/assets/icons/floor-dark.svg'
import rectangleDarkIcon from 'browser/assets/icons/rectangle-dark.svg'
import rectangleLightIcon from 'browser/assets/icons/rectangle-light.svg'

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.dark};
  cursor: pointer;
  height: 100%;
`

const Main = styled.div`
  padding: 25px 15px;
  background: ${({ theme }) => theme.colors.gray200};
  ${({ theme }) => theme.mq.xl} {
    padding: 30px;
  }
`

const CardContent = styled.div`
  background: ${({ theme }) => theme.colors.dark200};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 140px;
  padding: 10px 30px 30px;
  margin-top: auto;
`

const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.light};
  padding-top: 10px;

  span {
    margin-left: 5px;
    text-transform: lowercase;
  }
`

const CardButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 70%;
  height: 50px;
  max-width: 215px;
  background-color: ${({ theme }) => theme.colors.primary};

  span {
    font-size: ${({ theme }) => theme.fontSize.xs};
    color: ${({ theme }) => theme.colors.dark200};
  }

  & > :last-child {
    position: absolute;
    top: 50%;
    right: 12%;
    transform: translateY(-50%);
  }
`

const Wrapper = styled.article`
  display: flex;
  position: relative;
  flex-direction: column;
  transition: 0.3s;
  max-width: 420px;
  justify-self: center;

  &:hover ${Main} {
    background: ${({ theme }) => theme.colors.primary};
  }

  &:hover ${CardContent} {
    background: ${({ theme }) => theme.colors.primary};

    ${ContentItem} {
      ${Label} {
        color: ${({ theme }) => theme.colors.dark200};
      }
    }
  }

  &:hover ${CardButton} {
    background: ${({ theme }) => theme.colors.dark200};
    span {
      color: ${({ theme }) => theme.colors.light};
    }
  }
`

const CardHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
`

const Heading = styled.h5`
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.regular};
`

// const FavBtn = styled.button`
//   position: absolute;
//   top: 30px;
//   right: 15px;
//   padding: 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: transparent;
//   cursor: pointer;
//   ${({ theme }) => theme.mq.xl} {
//     top: 35px;
//     right: 35px;
//   }
//   &:hover {
//     span {
//       opacity: 1;
//       visibility: visible;
//       transform: scale(1) translate(0, -50%);
//     }
//   }
//   span {
//     display: block;
//     padding: 2px 5px;
//     white-space: nowrap;
//     position: absolute;
//     pointer-events: none;
//     top: 50%;
//     right: 25px;
//     z-index: 1;
//     transition: 0.3s;
//     background: #fff;
//     border: 1px solid ${({ theme }) => theme.colors.gray300};
//     opacity: 0;
//     transform: scale(0.7) translate(20px, -50%);
//     visibility: hidden;
//     border-radius: 4px;
//     box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
//   }
// `

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  width: 100%;
  height: 260px;
  overflow: hidden;
  img {
    height: auto;
    width: 100%;
    max-height: 290px;
  }
  & > div:first-child {
    height: 100%;
  }
  ${({ theme }) => theme.mq.md} {
    height: 350px;
    img {
      height: auto;
      width: auto;
      max-height: 100%;
    }
  }
  ${({ theme }) => theme.mq.lg} {
    height: 300px;
  }
  ${({ theme }) => theme.mq.xxxl} {
    height: 350px;
  }
`

// const CardFooterText = styled.p`
//   color: ${({ theme }) => theme.colors.primary};
//   font-size: 2rem;
// `

// const CardFooter = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   margin-top: 10px;
//   padding: 10px 0;
//   border-top: 1px solid #f2ece7;
//   svg {
//     fill: ${({ theme }) => theme.colors.primary};
//   }
// `

// const StyledLabel = styled(Label)`
//   padding: 0;
//   align-items: flex-start;
//   span {
//     margin-top: 6px;
//   }
// `

// const Highlight = styled.span`
//   margin-left: 10px;
//   color: ${({ theme }) => theme.colors.primary};
// `

// const FloorsWrapper = styled.div`
//   display: flex;
//   justify-content: flex-start;
//   flex-wrap: wrap;
//   margin-left: 5px;
// `

// const FloorBtn = styled.button`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 50%;
//   width: 30px;
//   height: 30px;
//   cursor: pointer;
//   font-weight: 500;
//   padding: 0;
//   font-size: 1.5rem;
//   transition: 0.3s;
//   background: ${({ theme, active }) =>
//     active ? theme.colors.white200 : 'transparent'};
//   color: ${({ theme, active }) =>
//     active ? theme.colors.primary : theme.colors.dark};
//   cursor: ${({ active }) => (active ? 'default' : 'pointer')};
//   &:hover {
//     background: ${({ theme, active }) =>
//       active ? theme.colors.white200 : theme.colors.white100};
//   }
//   ${({ disabled }) =>
//     disabled &&
//     css`
//       display: none;
//       /* opacity: 0.4;
//       cursor: not-allowed;
//       &:hover {
//         background: transparent;
//       } */
//     `}
// `

// const Row = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// `

// const Column = styled.div`
//   display: flex;
//   flex-direction: column;
//   flex: 1;
// `

// const ButtonWrapper = styled.div`
//   margin-bottom: 5px;
//   button {
//     height: 40px;
//     padding: 0 15px;
//     @media (max-width: 350px) {
//       font-size: 1.3rem;
//       padding: 0 10px;
//     }
//   }
// `

// const ViewsWrapper = styled.div`
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   display: flex;
//   flex-direction: column;
// `

// const PlanButton = styled.button`
//   position: relative;
//   cursor: pointer;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border: 1px solid ${({ theme }) => theme.colors.gray300};
//   background: ${({ theme }) => theme.colors.white200};
//   transition: 0.3s;
//   width: 40px;
//   height: 40px;
//   &:not(:last-child) {
//     border-bottom: none;
//   }
//   div {
//     color: #cec3bb;
//     font-weight: 700;
//     font-size: 1.6rem;
//   }
//   svg {
//     fill: #cec3bb;
//     transition: 0.3s;
//   }
//   ${({ active }) =>
//     active &&
//     css`
//       background: #fff;
//       svg {
//         fill: ${({ theme }) => theme.colors.primary};
//       }
//     `}
//   &:hover {
//     span {
//       opacity: 1;
//       visibility: visible;
//       transform: scale(1) translate(0, -50%);
//     }
//   }
//   span {
//     display: block;
//     padding: 2px 5px;
//     white-space: nowrap;
//     pointer-events: none;
//     position: absolute;
//     top: 50%;
//     right: 45px;
//     z-index: 1;
//     transition: 0.3s;
//     background: #fff;
//     border: 1px solid ${({ theme }) => theme.colors.gray300};
//     opacity: 0;
//     transform: scale(0.7) translate(20px, -50%);
//     visibility: hidden;
//     border-radius: 4px;
//     box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
//   }
// `

// const PromoLabel = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   text-transform: uppercase;
//   padding: 5px 8px;
//   background: ${({ theme }) => theme.colors.primary};
//   color: #fff;
//   max-width: max-content;
//   font-weight: 700;
//   font-size: 1.4rem;
//   border-radius: 4px;
//   min-width: 80px;
//   margin: 4px 0;
// `

const FlatCard = ({
  flatData,
  //  similarFlats,
  // showTour3D,
  // toggleForm
}) => {
  // const history = useHistory()
  // const [viewType, setViewType] = useState('flat')
  const [visibleFlat, setVisibleFlat] = useState(flatData)

  const [isHovered, setIsHovered] = useState(false)

  const {
    name,
    PNG,
    PNGFloor,
    rooms,
    // status,
    floor,
    area,
    // price,
    // isPriceVisible,
    // tour3D,
    stage,
  } = visibleFlat

  useEffect(() => {
    setVisibleFlat(flatData)
  }, [flatData])

  // const includedFloors = visibleFlat.similarFlats
  //   ? [
  //       ...new Set(
  //         visibleFlat.similarFlats.map((el) => el.floor).sort((a, b) => a - b),
  //       ),
  //     ]
  //   : [floor]
  const { translate } = useContext(LangContext)

  // const checkIsFloorDisabled = (el) => {
  //   return (
  //     floor !== el &&
  //     (!similarFlats ||
  //       !similarFlats.map((layout) => layout.floor).includes(el))
  //   )
  // }

  // const handleFloorClick = (clickedFloor) => {
  //   if (!checkIsFloorDisabled(clickedFloor)) {
  //     const newVisibleFlat = similarFlats.find(
  //       (el) => el.floor === clickedFloor,
  //     )
  //     setVisibleFlat(newVisibleFlat)
  //   }
  // }

  // const handleTourButton = () => {
  //   showTour3D(tour3D, name)
  //   if (window.dataLayer)
  //     window.dataLayer.push({
  //       event: 'walk',
  //       mieszkanie: name,
  //     })
  // }

  // const isFavourite = favourites.includes(name)
  // const toggleFavourite = () => {
  //   if (isFavourite) {
  //     dispatch({ type: 'REMOVE_FROM_FAVOURITES', payload: name })
  //   } else {
  //     dispatch({ type: 'ADD_TO_FAVOURITES', payload: name })
  //   }
  // }

  return (
    <Wrapper
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      {/* <FavBtn
        onClick={toggleFavourite}
        aria-label={
          !isFavourite
            ? translate('addToWishlist')
            : translate('removeFromWishlist')
        }
      >
        <span>
          {isFavourite
            ? translate('removeFromWishlist')
            : translate('addToWishlist')}
        </span>
        {isFavourite ? (
          <Icon src={fullHeartIcon} alt="full heart" size={18} />
        ) : (
          <Icon src={heartIcon} alt="heart" size={18} />
        )}
      </FavBtn> */}
      <a href={`/mieszkanie/${stage}/${name}`}>
        <InnerWrapper>
          <Main>
            <CardHeading>
              <Heading
                dangerouslySetInnerHTML={{
                  __html: `${translate('flat')} nr ${name}`,
                }}
              />
              <Heading
                dangerouslySetInnerHTML={{
                  __html: `${translate('stage')} ${stage}`,
                }}
              />
            </CardHeading>
            <ImageWrapper>
              <LoadedImage
                src={PNG}
                srcAlt={PNGFloor}
                alt={`${name} plan`}
                full
              />
              {/* {PNG && PNGFloor && (
                <ViewsWrapper>
                  <PlanButton
                    onClick={() => setViewType('flat')}
                    active={viewType === 'flat'}
                  >
                    <span>{translate('flatView')}</span>
                    <svg
                      id="house-plan"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20.361"
                      height="20.361"
                      viewBox="0 0 20.361 20.361"
                    >
                      <path
                        id="Path_197"
                        data-name="Path 197"
                        d="M19.765,0H.6A.6.6,0,0,0,0,.6V13.362a.6.6,0,0,0,.6.6H3.571l1.1,1.1a.6.6,0,1,0,.844-.844L4.24,12.94A.606.606,0,0,0,4.02,12.8a15.576,15.576,0,0,0-2.827-.036V8.757l.254.254a.6.6,0,1,0,.844-.844l-1.1-1.1V1.193H7.357V6.721H4.136a.6.6,0,1,0,0,1.193h6.045a.6.6,0,0,0,0-1.193H8.55V1.193H19.168V6.721c-6.263,0-5.866-.016-6.009.036a.605.605,0,0,0-.219.139L11.668,8.168a.6.6,0,0,0,.844.844l1.1-1.1h5.559v4.852H13.362a.6.6,0,0,0,0,1.193h5.806v5.21h-5.21V16.544a.6.6,0,0,0-.6-.6H10.777V13.609l1.1-1.1a.6.6,0,0,0-.844-.844l-1.1,1.1H7a.6.6,0,0,0,0,1.193H9.584v2.585a.6.6,0,0,0,.6.6h2.585v2.625a.6.6,0,0,0,.6.6h6.4a.6.6,0,0,0,.6-.6V.6A.6.6,0,0,0,19.765,0Z"
                      />
                    </svg>
                  </PlanButton>
                  <PlanButton
                    onClick={() => setViewType('3D')}
                    active={viewType === '3D'}
                  >
                    <span>{translate('floorView')}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="20.377"
                      viewBox="0 0 13 20.377"
                    >
                      <g id="residential-block" transform="translate(-8.357)">
                        <path
                          id="Path_196"
                          data-name="Path 196"
                          d="M21.357.963A.947.947,0,0,0,20.4,0H9.291a.932.932,0,0,0-.934.963V20.377H12.6V17.169a.55.55,0,0,1,.542-.573h3.43a.553.553,0,0,1,.546.573v3.207h4.24V.963ZM14.073,13.774a.333.333,0,0,1-.333.333H11.732a.333.333,0,0,1-.333-.333V11.766a.334.334,0,0,1,.333-.334H13.74a.333.333,0,0,1,.333.334Zm0-4.426a.333.333,0,0,1-.333.333H11.732a.333.333,0,0,1-.333-.333V7.34a.333.333,0,0,1,.333-.333H13.74a.333.333,0,0,1,.333.333Zm0-4.518a.333.333,0,0,1-.333.333H11.732A.333.333,0,0,1,11.4,4.83V2.822a.333.333,0,0,1,.333-.333H13.74a.333.333,0,0,1,.333.333Zm4.242,8.944a.334.334,0,0,1-.334.333H15.973a.333.333,0,0,1-.333-.333V11.766a.333.333,0,0,1,.333-.334h2.008a.334.334,0,0,1,.334.334Zm0-4.426a.334.334,0,0,1-.334.333H15.973a.333.333,0,0,1-.333-.333V7.34a.333.333,0,0,1,.333-.333h2.008a.334.334,0,0,1,.334.333Zm0-4.518a.334.334,0,0,1-.334.333H15.973a.333.333,0,0,1-.333-.333V2.822a.333.333,0,0,1,.333-.333h2.008a.334.334,0,0,1,.334.333Z"
                        />
                      </g>
                    </svg>
                  </PlanButton>
                  {tour3D && (
                    <PlanButton onClick={handleTourButton}>
                      <span>{translate('tour3d')}</span>
                      <div>3D</div>
                    </PlanButton>
                  )}
                </ViewsWrapper>
              )} */}
            </ImageWrapper>
          </Main>
          <CardContent>
            <ContentItem>
              <Icon
                src={isHovered ? roomsIconDark : roomsIcon}
                size={20}
                alt="content-item"
              />
              <Label>
                {rooms}
                <span>{translate('rooms')} </span>
              </Label>
            </ContentItem>
            {/* {status === 1 && (
            <ButtonWrapper>
            <Button onClick={() => toggleForm(flatData, true)} noHover>
            {translate('book')}
            </Button>
            </ButtonWrapper>
          )} */}
            <ContentItem>
              <Icon
                src={isHovered ? areaIconDark : areaIcon}
                size={20}
                alt="content-item"
              />
              <Label>
                {floor}
                <span>{translate('floor')} </span>
              </Label>
            </ContentItem>
            <ContentItem>
              <Icon
                src={isHovered ? floorIconDark : floorIcon}
                size={20}
                alt="content-item"
              />
              <Label>
                {area} m<sup>2</sup>
              </Label>
            </ContentItem>
            {/* {isPriceVisible && (
            <Label>
            <span>{translate('price')}: </span>
            <Highlight>
            {price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} zł
            </Highlight>
            </Label>
            )}
          {!isPriceVisible && <PromoLabel>{translate('sale')}</PromoLabel>} */}
            {/* <CardFooter>
            <CardFooterText>{translate('checkOut')}</CardFooterText>
            <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.178"
            height="12"
            viewBox="0 0 18.178 12"
            >
            <path
            d="M13.181,6.006a.718.718,0,0,0,.012.937l.076.074,4.761,3.995H3.217a.718.718,0,0,0-.711.62l-.007.1a.718.718,0,0,0,.718.718H18.058l-4.81,4.036a.718.718,0,0,0,.922,1.1l6.23-5.227a.863.863,0,0,0,.1-1.134l-.077-.075-6.23-5.227A.718.718,0,0,0,13.181,6.006Z"
            transform="translate(-2.5 -5.75)"
            />
            </svg>
          </CardFooter> */}
            <CardButton>
              <span>{translate('checkOut')}</span>
              <Icon
                src={isHovered ? rectangleLightIcon : rectangleDarkIcon}
                size={12}
                alt="rectangle"
              />
            </CardButton>
          </CardContent>
        </InnerWrapper>
      </a>
    </Wrapper>
  )
}

FlatCard.propTypes = {
  similarFlats: PropTypes.arrayOf(PropTypes.object),
  flatData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  showTour3D: PropTypes.func.isRequired,
  toggleForm: PropTypes.func.isRequired,
}

FlatCard.defaultProps = {
  similarFlats: null,
}

export default FlatCard
