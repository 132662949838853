import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Cookie from 'js-cookie'
import Container from 'components/shared/container'

const CookiesWrapper = styled.aside`
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  width: 90%;
  display: block;
  transition: 0.3s ease;
  opacity: 1;
  max-width: max-content;
  box-shadow: 4px 4px 11px 0px ${({ theme }) => theme.colors.dark200};
  border-radius: 10px;
  ${Container} {
    max-width: max-content;
    margin: 0;
  }

  ${({ theme }) => theme.media.lg.min} {
    left: 50px;
    transform: none;
  }

  ${({ theme }) => theme.media.s.min} {
    bottom: 20px;
  }

  ${({ hidden }) =>
    hidden &&
    css`
      opacity: 0;
      transform: translateY(100px);
    `}
`

const StyledContainer = styled(Container)`
  padding: 0;
  border-radius: 10px;
`

const CookiesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  background: ${({ theme }) => theme.colors.primary};
  max-width: 700px;
  padding: 20px 18px;
  border-radius: 10px;
  ${({ theme }) => theme.media.s.min} {
    align-items: center;
    padding: 25px 35px;
  }
`

const CookiesButton = styled.button`
  height: 50px;
  padding: 12px;
  font-size: 18px;
  background-color: ${({ theme }) => theme.colors.dark200};
  margin-left: 15px;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.media.s.min} {
    margin-left: 30px;
  }
`

const CookiesText = styled.p`
  text-align: justify;
  font-size: 0.95rem;
  a {
    margin-left: 4px;
    color: ${({ theme }) => theme.colors.dark200};
    text-decoration: none;
  }
  ${({ theme }) => theme.media.md.min} {
    padding-right: 15px;
    font-size: 1.1rem;
    text-align: left;
  }
`

const Cookies = () => {
  const [visible, setVisible] = useState(false)
  const [removeAlert, setRemoveAlert] = useState(false)

  const acceptCookies = () => {
    Cookie.set('dolina-sanu-cookies', true)
    setVisible(false)
    setTimeout(() => setRemoveAlert(true), 500)
    if (localStorage) {
      localStorage.setItem('dolina-sanu-cookies', true)
    }
  }

  useEffect(() => {
    if (localStorage) {
      const value = localStorage.getItem('dolina-sanu-cookies')
      if (value && Boolean(value) === true) {
        setVisible(false)
        setTimeout(() => setRemoveAlert(true), 500)
      } else {
        setVisible(true)
      }
    } else {
      setVisible(true)
    }
  }, [])

  return (
    <>
      {!removeAlert && (
        <CookiesWrapper hidden={!visible}>
          <StyledContainer>
            <CookiesContainer>
              <CookiesText>
                Strona korzysta z plików cookies w celu realizacji usług. Możesz
                określić warunki przechowywania lub dostępu do plików cookies w
                Twojej przeglądarce.
                <a href="/polityka-prywatnosci">Polityka Prywatności</a>
              </CookiesText>
              <CookiesButton onClick={acceptCookies}>OK</CookiesButton>
            </CookiesContainer>
          </StyledContainer>
        </CookiesWrapper>
      )}
    </>
  )
}

export default Cookies
