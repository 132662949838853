import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import Container from 'components/shared/container'
import rectangleWhiteIcon from 'assets/icons/rectangle-white.svg'
import arrowLight from 'assets/icons/arrow-light.svg'
import arrowLight2 from 'assets/icons/arrow-light-2.svg'

import useBreakpoint from 'hooks/useBreakpoint'

import LazyImage from 'components/shared/lazyImage'
import Icon from 'components/shared/icon'
import { Heading, Text } from 'components/shared/typography'

import { ImageDataLike } from 'gatsby-plugin-image'

type Props = {
  title: string
  description: string
  winterImg: ImageDataLike
  winterImgMobile: ImageDataLike
  winterPlaces: Array<{
    locationWinterPlacesSingle: string
  }>
  summerImg: ImageDataLike
  summerImgMobile: ImageDataLike
  summerPlaces: Array<{
    locationSummerPlacesSingle: string
  }>
  cloudImg: ImageDataLike
  winterText: string
  summerText: string
}

const Section = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`

const StyledContainer = styled(Container)`
  align-self: flex-start;
  ${({ theme }) => theme.media.lg.min} {
    width: 55%;
    max-width: 770px;
    margin: 0 0 0 40px;
  }
`

const StyledHeading = styled(Heading)`
  ${({ theme }) => theme.media.s.min} {
    & > br:nth-child(2) {
      display: none;
    }
  }
`

const ImgInfo = styled.div<{ x?: string }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 300px;
  max-width: 300px;

  ${({ theme }) => theme.media.lg.min} {
    display: none;
  }
`

const ImgInfoWinterDesktop = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-138%, -50%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 340px;
  max-width: 340px;
  opacity: 1;
`

const ImgInfoSummerDesktop = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(40%, -50%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 340px;
  max-width: 340px;
  opacity: 0;
`

const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;

  ${({ theme }) => theme.media.lg.min} {
    height: 640px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    height: 800px;
  }
`

const SlideWrapper = styled.div<{ width: any; onTop?: boolean }>`
  position: relative;

  :first-child {
    margin-bottom: 80px;
  }

  ${({ theme }) => theme.media.s.min} {
    :first-child {
      margin-bottom: 0;
    }
  }

  ${({ theme }) => theme.media.lg.min} {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    ${({ onTop }) =>
      onTop &&
      css`
        z-index: 1;
        width: ${({ width }) => (width ? `${width}px` : '30vw')};
      `}
  }
`

const ImageWrapper = styled.div`
  width: 100vw;
  height: 100%;
`

const PlacesList = styled.ul`
  margin: 20px 0 0 0;
`

const PlaceItem = styled.li<{ additionalInfo?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  // Rectangle white icon
  & > :first-child {
    margin-right: 12px;
  }

  ${({ additionalInfo }) =>
    additionalInfo &&
    css`
      padding-left: 23px;
    `}
`

const CloudWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, 15%);
  width: 800px;
  z-index: 15;

  ${({ theme }) => theme.media.s.min} {
    transform: translate(-40%, 0%);
  }

  ${({ theme }) => theme.media.sm.min} {
    width: 1100px;
    transform: translate(-79%, -45%);
  }

  ${({ theme }) => theme.media.lg.min} {
    transform: translate(-73%, 50%);
    top: unset;
    bottom: 0;
    width: 1500px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    transform: translate(-81%, 50%);
    width: 1750px;
  }
`

const CloudWrapper2 = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-60%, 15%);
  width: 800px;
  z-index: 15;

  ${({ theme }) => theme.media.s.min} {
    transform: translate(-60%, 0%);
  }

  ${({ theme }) => theme.media.sm.min} {
    width: 1100px;
    transform: translate(-61%, -40%);
  }

  ${({ theme }) => theme.media.lg.min} {
    transform: translate(-60%, 50%);
    top: unset;
    bottom: 0;
    width: 1500px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    transform: translate(-64%, 50%);
    width: 1750px;
  }
`

const CloudWrapper3 = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 15%);
  width: 800px;
  z-index: 15;

  ${({ theme }) => theme.media.s.min} {
    transform: translate(-50%, 0%);
  }

  ${({ theme }) => theme.media.sm.min} {
    width: 1100px;
    transform: translate(-19%, -45%);
  }

  ${({ theme }) => theme.media.lg.min} {
    transform: translate(-28%, 50%);
    top: unset;
    bottom: 0;
    width: 1500px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    transform: translate(-47%, 50%);
    width: 1750px;
  }
`

const CloudWrapper4 = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 15%);
  width: 800px;
  z-index: 15;

  ${({ theme }) => theme.media.s.min} {
    transform: translate(-50%, 0%);
  }

  ${({ theme }) => theme.media.sm.min} {
    width: 1100px;
    transform: translate(0%, -45%);
  }

  ${({ theme }) => theme.media.lg.min} {
    transform: translate(-28%, 50%);
    top: unset;
    bottom: 0;
    width: 1500px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    transform: translate(-36%, 50%);
    width: 1750px;
  }
`

const CloudWrapper5 = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-27%, 55%);
  width: 1200px;
  z-index: 15;

  ${({ theme }) => theme.media.sm.min} {
    width: 1100px;
    transform: translate(-43%, 60%);
    width: 1400px;
  }

  ${({ theme }) => theme.media.lg.min} {
    transform: translate(5%, 50%);
    top: unset;
    bottom: 0;
    width: 1500px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    transform: translate(-4%, 50%);
    width: 1750px;
  }
`

const CloudWrapper6 = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 60%);
  width: 1200px;
  z-index: 15;

  ${({ theme }) => theme.media.sm.min} {
    transform: translate(-61%, 60%);
    width: 1400px;
  }

  ${({ theme }) => theme.media.lg.min} {
    transform: translate(5%, 50%);
    top: unset;
    bottom: 0;
    width: 1500px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    transform: translate(15%, 50%);
    width: 1750px;
  }
`

const TipWrapper = styled.figure`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  z-index: 10;
  transition: 0.3s;
`

const TipInnerWrapper = styled.div`
  position: relative;
  height: 100%;
`

const TipButtonWrapper = styled.div<{ width: any }>`
  position: absolute;
  top: 0;
  left: -57px;
  margin-top: -42px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 114px;
  height: 114px;
  border: 1px solid ${({ theme }) => theme.colors.light};
  border-radius: 50%;
  z-index: 1;
  transform: ${({ width }) =>
    width ? `translateX(${width}px)` : 'translateX(30vw)'};
  span {
    font-size: 1rem;
    text-align: center;
  }

  &:before {
    content: '';
    position: absolute;
    height: 500px;
    bottom: -300px;
    width: 3px;
    background-color: ${({ theme }) => theme.colors.light};
    height: 320px;
    z-index: -1;
  }
`

const TipButton = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 86px;
  height: 86px;
  background: transparent;
  border-radius: 50%;
  cursor: col-resize;
  user-select: none;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  z-index: 1;
`

const TipArrowWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  :first-child {
    left: 24px;
  }

  :nth-child(2) {
    right: 24px;
  }
`

const TipLabel = styled.span`
  position: absolute;
  bottom: -70px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  color: ${({ theme }) => theme.colors.light};
`

const Season: React.FC<Props> = ({
  title,
  description,
  winterImg,
  winterImgMobile,
  winterPlaces,
  summerImg,
  summerImgMobile,
  summerPlaces,
  cloudImg,
  winterText,
  summerText,
}) => {
  const [isDragging, setIsDragging] = useState(false)
  const [width, setWidth] = useState(null)

  const { lg, xxl } = useBreakpoint()

  const handleDragMouseUp = () => {
    setIsDragging(false)
  }

  const handleDragMouseDown = () => {
    setIsDragging(true)
  }

  const handleImageResize = (e) => {
    if (isDragging) {
      const newWidth =
        typeof e.clientX !== 'undefined' ? e.clientX : e.touches[0].clientX
      if (!xxl && newWidth > 40 && newWidth < window.innerWidth - 40)
        setWidth(newWidth)
      if (xxl && newWidth > 520 && newWidth < window.innerWidth - 520)
        setWidth(newWidth)
    }
  }

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleImageResize)
      window.addEventListener('mouseup', handleDragMouseUp)
      window.addEventListener('touchmove', handleImageResize)
      window.addEventListener('touchend', handleDragMouseUp)
    }
    return () => {
      window.removeEventListener('mousemove', handleImageResize)
      window.removeEventListener('mouseup', handleDragMouseUp)
      window.removeEventListener('touchmove', handleImageResize)
      window.removeEventListener('touchend', handleDragMouseUp)
    }
  }, [isDragging])

  const changeOpacity = (id: string) => {
    const imgInfo = document.getElementById(id)
    imgInfo.style.opacity = 1
  }

  const changeOpacity2 = (id: string) => {
    const imgInfo = document.getElementById(id)
    imgInfo.style.opacity = 0
  }

  useEffect(() => {
    const slideWrapperWinter = document.querySelector('#slide-wrapper-winter')
    const slideWrapperSummer = document.querySelector('#slide-wrapper-summer')
    const imgInfoWinter = document.querySelector('#img-info-winter')
    const imgInfoSummer = document.querySelector('#img-info-summer')

    slideWrapperWinter?.addEventListener('mouseover', () =>
      changeOpacity('img-info-winter'),
    )

    slideWrapperWinter?.addEventListener('mouseout', () =>
      changeOpacity2('img-info-winter'),
    )

    imgInfoWinter?.addEventListener('mouseover', () =>
      changeOpacity('img-info-winter'),
    )

    imgInfoWinter?.addEventListener('mouseout', () =>
      changeOpacity2('img-info-winter'),
    )

    slideWrapperSummer?.addEventListener('mouseover', () =>
      changeOpacity('img-info-summer'),
    )

    slideWrapperSummer?.addEventListener('mouseout', () =>
      changeOpacity2('img-info-summer'),
    )

    imgInfoSummer?.addEventListener('mouseover', () =>
      changeOpacity('img-info-summer'),
    )

    imgInfoSummer?.addEventListener('mouseout', () =>
      changeOpacity2('img-info-summer'),
    )
  }, [isDragging])

  return (
    <Section title={title.replace(/<br\/> /g, '')}>
      <StyledContainer>
        <StyledHeading
          as="h3"
          size={42}
          weight={500}
          margin="40px"
          themecolor="dark100"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Text
          size={16}
          line={2.2}
          margin="50px"
          themecolor="dark100"
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </StyledContainer>
      <CarouselWrapper>
        <SlideWrapper width={width} onTop id="slide-wrapper-winter">
          <ImageWrapper>
            <LazyImage
              src={lg ? winterImg : winterImgMobile}
              alt="winter"
              objectFit="cover"
            />
          </ImageWrapper>
          <ImgInfo x={`${width}%`}>
            <Heading
              as="h5"
              size={42}
              weight={500}
              margin="30px"
              themecolor="white"
            >
              Zimą czekają na Ciebie stacje narciarskie:
            </Heading>
            <Text
              themecolor="light"
              size={14}
              dangerouslySetInnerHTML={{ __html: winterText }}
            />
            <PlacesList>
              {winterPlaces.map(({ locationWinterPlacesSingle }) => (
                <PlaceItem key={`winter-${locationWinterPlacesSingle}`}>
                  <Icon
                    src={rectangleWhiteIcon}
                    width={16}
                    alt="rectangle-white"
                  />
                  <Text
                    themecolor="light"
                    size={14}
                    dangerouslySetInnerHTML={{
                      __html: locationWinterPlacesSingle,
                    }}
                  />
                </PlaceItem>
              ))}
              <PlaceItem additionalInfo>
                <Text
                  themecolor="light"
                  size={14}
                  dangerouslySetInnerHTML={{
                    __html:
                      'oraz kuligi konne i oferta bieszczadzkich krytych pływalni',
                  }}
                />
              </PlaceItem>
            </PlacesList>
          </ImgInfo>
        </SlideWrapper>
        {lg && (
          <TipWrapper>
            <TipInnerWrapper>
              <TipButtonWrapper width={width}>
                <TipButton
                  onMouseDown={handleDragMouseDown}
                  onTouchStart={handleDragMouseDown}
                >
                  <TipArrowWrapper>
                    <Icon src={arrowLight2} width={8} alt="arrow" />
                  </TipArrowWrapper>
                  <TipArrowWrapper>
                    <Icon src={arrowLight} width={8} alt="arrow" />
                  </TipArrowWrapper>
                  <TipLabel>Złap i przesuń</TipLabel>
                </TipButton>
                <ImgInfoWinterDesktop id="img-info-winter">
                  <Heading
                    as="h5"
                    size={38}
                    weight={500}
                    margin="30px"
                    themecolor="white"
                  >
                    Zimą czekają na Ciebie stacje narciarskie:
                  </Heading>
                  <Text
                    themecolor="light"
                    dangerouslySetInnerHTML={{ __html: winterText }}
                  />
                  <PlacesList>
                    {winterPlaces.map(({ locationWinterPlacesSingle }) => (
                      <PlaceItem key={`winter2-${locationWinterPlacesSingle}`}>
                        <Icon
                          src={rectangleWhiteIcon}
                          width={12}
                          alt="rectangle-white"
                        />
                        <Text
                          themecolor="light"
                          dangerouslySetInnerHTML={{
                            __html: locationWinterPlacesSingle,
                          }}
                        />
                      </PlaceItem>
                    ))}
                    <PlaceItem additionalInfo>
                      <Text
                        themecolor="light"
                        dangerouslySetInnerHTML={{
                          __html:
                            'oraz kuligi konne i oferta bieszczadzkich krytych pływalni',
                        }}
                      />
                    </PlaceItem>
                  </PlacesList>
                </ImgInfoWinterDesktop>
                <ImgInfoSummerDesktop id="img-info-summer">
                  <Heading
                    as="h5"
                    size={38}
                    weight={500}
                    margin="30px"
                    themecolor="white"
                  >
                    Od wiosny do jesieni urzekną Cię takie atrakcje jak:
                  </Heading>
                  <Text
                    themecolor="light"
                    dangerouslySetInnerHTML={{ __html: summerText }}
                  />
                  <PlacesList>
                    {summerPlaces.map(({ locationSummerPlacesSingle }) => (
                      <PlaceItem key={`summer-${locationSummerPlacesSingle}`}>
                        <Icon
                          src={rectangleWhiteIcon}
                          width={12}
                          alt="rectangle-white"
                        />
                        <Text
                          themecolor="light"
                          dangerouslySetInnerHTML={{
                            __html: locationSummerPlacesSingle,
                          }}
                        />
                      </PlaceItem>
                    ))}
                  </PlacesList>
                </ImgInfoSummerDesktop>
              </TipButtonWrapper>
            </TipInnerWrapper>
          </TipWrapper>
        )}
        <SlideWrapper width={width} id="slide-wrapper-summer">
          <ImageWrapper>
            <LazyImage
              src={lg ? summerImg : summerImgMobile}
              alt="summer"
              objectFit="cover"
            />
          </ImageWrapper>
          <ImgInfo x={`${width}%`}>
            <Heading
              as="h5"
              size={42}
              weight={500}
              margin="30px"
              themecolor="white"
            >
              Od wiosny do jesieni urzekną Cię takie atrakcje jak:
            </Heading>
            <Text
              themecolor="light"
              size={14}
              dangerouslySetInnerHTML={{ __html: summerText }}
            />
            <PlacesList>
              {summerPlaces.map(({ locationSummerPlacesSingle }) => (
                <PlaceItem key={`summer2-${locationSummerPlacesSingle}`}>
                  <Icon src={rectangleWhiteIcon} alt="rectangle-white" />
                  <Text
                    themecolor="light"
                    size={14}
                    dangerouslySetInnerHTML={{
                      __html: locationSummerPlacesSingle,
                    }}
                  />
                </PlaceItem>
              ))}
            </PlacesList>
          </ImgInfo>
        </SlideWrapper>
      </CarouselWrapper>
      <CloudWrapper>
        <LazyImage src={cloudImg} alt="cloud" />
      </CloudWrapper>
      <CloudWrapper2>
        <LazyImage src={cloudImg} alt="cloud" />
      </CloudWrapper2>
      <CloudWrapper3>
        <LazyImage src={cloudImg} alt="cloud" />
      </CloudWrapper3>
      <CloudWrapper4>
        <LazyImage src={cloudImg} alt="cloud" />
      </CloudWrapper4>
      <CloudWrapper5>
        <LazyImage src={cloudImg} alt="cloud" />
      </CloudWrapper5>
      <CloudWrapper6>
        <LazyImage src={cloudImg} alt="cloud" />
      </CloudWrapper6>
    </Section>
  )
}

export default Season
