import { Investment } from 'browser/schemes'

const buildings = new Map()

const investment = new Investment({
  investmentName: 'Dolina Sanu',
  flatsURL: '',
  mailerURL: '',
  buildings,
})

export default investment
