const getFlatsWithProperties = async (flats) => {
  const FLATS = flats.map((flat, index) => {
    const stage = flat.flatBuilding

    return {
      investmentID: '99',
      investment: 'W Dolinie Sanu',
      building: flat.flatBuilding,
      name: flat.flatNumber,
      price: flat.flatPrice,
      isPriceVisible: flat.flatPriceVisibility,
      area: flat.flatSize,
      floor: flat.flatFloor,
      rooms: flat.flatRooms,
      balcony: flat.flatBalcony,
      status: flat.flatStatus,
      id: flat.flatId,
      type: flat.flatType,
      stage,
      PDF: `https://serwer2283083.home.pl/CMS/flats/Etap%20${stage}/PDF/w-dolinie-sanu-${stage}-${flat.flatNumber}.pdf`,
      PNG: `https://serwer2283083.home.pl/CMS/flats/Etap%20${stage}/PNG2/w-dolinie-sanu-${stage}-${flat.flatNumber}.png`,
      PNGFloor: `https://serwer2283083.home.pl/CMS/flats/Etap%20${stage}/PNG/w-dolinie-sanu-${stage}-${flat.flatNumber}.png`,
      PNG3D: '',
      PNG360: [],
      similarFlats: null,
      tour3D: null,
    }
  })
  return FLATS
}

export default getFlatsWithProperties
