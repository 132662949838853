import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import ModalSwiper from 'components/layout/About/ModalSwiper'

import { Heading, Text } from 'components/shared/typography'

import LazyImage from 'components/shared/lazyImage'
import Icon from 'components/shared/icon'
import { ImageDataLike } from 'gatsby-plugin-image'

import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

import rectangleLight from 'assets/icons/rectangle-light.svg'
import rectangleDark from 'assets/icons/rectangle-dark.svg'

import rectangleBtnLeft from 'assets/icons/rectangle-button-left.svg'
import rectangleBtnRight from 'assets/icons/rectangle-button-right.svg'

import useBreakpoint from 'hooks/useBreakpoint'

export type Gallery = Array<{
  src: ImageDataLike
  alt: string
}>

type Props = {
  titleMobile: string
  titleDesktop: string
  descriptionMobile: string
  descriptionDesktop: string
  gallery: Gallery
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${({ theme }) => theme.media.lg.min} {
    flex-direction: column-reverse;
    width: 57.5%;
    padding-left: min(3.75vw, 50px);
  }
`

const TextContent = styled.div`
  align-self: center;
  ${({ theme }) => theme.media.lg.min} {
    align-self: flex-start;
    margin-top: 300px;
  }

  ${({ theme }) => theme.media.xl.min} {
    align-self: flex-start;
    margin-top: 400px;
  }

  @media (min-width: 1280px) {
    margin-top: 350px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    margin-top: 250px;
  }
`

const SwiperWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  align-self: center;
  margin-top: 30px;
  width: 100vw;
  max-width: 500px;
  transition: 0.3s;

  ${({ theme }) => theme.media.lg.min} {
    align-self: flex-start;
    max-width: 100%;
  }
`

const ControlButtons = styled.div`
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%, 50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% + 50px);
  z-index: 1;

  ${({ theme }) => theme.media.lg.min} {
    width: fit-content;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -205px;
  }

  ${({ theme }) => theme.media.xl.min} {
    bottom: -215px;
  }
`

const ControlButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const IndicatorButtons = styled.div`
  position: absolute;
  bottom: -90px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;

  ${({ theme }) => theme.media.lg.min} {
    bottom: -60px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    left: 50%;
  }
`

const IndicatorButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 6px;
`

const About1: React.FC<Props> = ({
  titleMobile,
  titleDesktop,
  descriptionMobile,
  descriptionDesktop,
  gallery,
}) => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null)
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)
  const [modalSwiperVisible, setModalSwiperVisible] = useState(false)

  const { lg } = useBreakpoint()

  const goPrev = () => {
    swiper?.slidePrev()
  }

  const goNext = () => {
    swiper?.slideNext()
  }

  const closeModalSwiper = () => setModalSwiperVisible(false)

  useEffect(() => {
    setActiveSlideIndex(0)
  }, [])

  return (
    <Wrapper>
      <TextContent>
        <Heading
          size={42}
          weight={500}
          margin="40px"
          themecolor="dark100"
          dangerouslySetInnerHTML={{ __html: lg ? titleDesktop : titleMobile }}
        />
        <Text
          size={16}
          line={2.1}
          themecolor="dark100"
          dangerouslySetInnerHTML={{
            __html: lg ? descriptionDesktop : descriptionMobile,
          }}
        />
      </TextContent>
      <SwiperWrapper>
        <Swiper
          modules={[Autoplay]}
          spaceBetween={0}
          slidesPerView={1}
          onSwiper={(initSwiper) => setSwiper(initSwiper)}
          // @ts-ignore
          onSlideChange={() => setActiveSlideIndex(swiper?.realIndex)}
          loop
          speed={1000}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
        >
          {gallery.map(({ src, alt }, index) => (
            <SwiperSlide
              key={index}
              onClick={() => setModalSwiperVisible(true)}
              style={{
                cursor: 'zoom-in',
              }}
            >
              <LazyImage src={src} alt={alt} />
            </SwiperSlide>
          ))}
        </Swiper>
        <ControlButtons>
          <ControlButton onClick={goPrev}>
            <Icon
              src={rectangleBtnLeft}
              width={lg ? 85 : 60}
              alt="rectangle-btn"
            />
          </ControlButton>
          <ControlButton onClick={goNext}>
            <Icon
              src={rectangleBtnRight}
              width={lg ? 85 : 60}
              alt="rectangle-btn"
            />
          </ControlButton>
        </ControlButtons>
        <IndicatorButtons>
          {gallery.map((i, index) => (
            <IndicatorButton
              key={`control-${index}`}
              type="button"
              onClick={() => {
                setActiveSlideIndex(index)
                swiper?.slideTo(index + 1)
              }}
            >
              <Icon
                width={12}
                src={
                  activeSlideIndex === index ? rectangleLight : rectangleDark
                }
                alt="rectangle"
              />
            </IndicatorButton>
          ))}
        </IndicatorButtons>
      </SwiperWrapper>
      {lg && (
        <ModalSwiper
          gallery={gallery}
          visible={modalSwiperVisible}
          close={closeModalSwiper}
          slideIndex={activeSlideIndex}
        />
      )}
    </Wrapper>
  )
}

export default About1
