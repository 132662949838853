import React from 'react'
import styled from 'styled-components'

import AskForm from 'components/layout/AskAboutFlat/AskForm'

import background from 'assets/images/background-diamond-pattern.png'

const Section = styled.section`
  padding: 40px 0;
  background-color: ${({ theme }) => theme.colors.white};
  background-image: url('${background}');
`

const formTitle = 'Zapytaj o mieszkanie'

const AskAboutFlat = () => {
  return (
    <Section title={formTitle} id="ask-form">
      <AskForm title={formTitle} />
    </Section>
  )
}

export default AskAboutFlat
