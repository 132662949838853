export const breakpoints = {
  xxs: 0,
  xs: 360,
  s: 440,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
  xxxl: 1920,
}

const theme = {
  colors: {
    primary: '#CFDED7',
    secondary: '#1C5868',
    light: '#FFEBC3',
    dark: '#1D4648',
    dark100: '#002238',
    dark200: '#1C5868',
    dark300: '#022D45',
    gray: '#BFC9C6',
    gray100: '#E1E8E6',
    gray200: '#F1F1F1',
    black: '#000000',
    white: '#FFFFFF',
    danger: '#CA0F0F',
  },
  fonts: {
    mainFont: `'Inter', sans-serif`,
  },
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  fontSize: {
    xs: '1.1rem',
    s: '1.4rem',
    m: '1.6rem',
    md: '1.8rem',
    lg: '2.1rem',
    lgm: '2.6rem',
    xl: '3rem',
    xlg: '3.2rem',
    xxl: '5rem',
    xxxl: '7.6rem',
    xxxxl: '9rem',
  },

  sizes: {
    navHeight: '65px',
    breadcrumbsHeight: '40px',
  },

  mq: Object.keys(breakpoints).reduce((acc, breakpoint) => {
    acc[breakpoint] = `@media (min-width: ${breakpoints[breakpoint]}px)`
    return acc
  }, {}),
}

export default theme
