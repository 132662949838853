import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Icon from 'browser/components/Icon'
import arrowRightIcon from 'browser/assets/icons/arrow-right-black.svg'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const PageBtn = styled.button`
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${({ theme }) => theme.medium};
  cursor: pointer;
  font-size: 1.6rem;
  background: ${({ theme, active }) =>
    active ? theme.colors.primaryLight : 'transparent'};
  color: ${({ theme, active }) =>
    active ? theme.colors.primary : theme.colors.dark};
  @media (hover: hover) {
    &:hover {
      background: ${({ theme, active }) =>
        active ? theme.colors.primaryLight : theme.colors.white100};
    }
  }
`

const NextPageBtn = styled(PageBtn)`
  background: ${({ theme }) => theme.colors.white200};
  &:hover {
    background: ${({ theme }) => theme.colors.white200};
  }
`

const PrevPageBtn = styled(NextPageBtn)`
  transform: rotate(180deg);
`

const Spacer = styled(PageBtn)`
  position: relative;
  cursor: default;
  &:after {
    content: '...';
    position: absolute;
    font-size: 2.6rem;
    top: -2px;
    left: 50%;
    transform: translateX(-50%);
  }
`

const Pagination = ({ activePage, setActivePage, totalPages, selector }) => {
  const changePage = (page) => {
    setActivePage(page)

    const flatsContent = document.querySelector(selector)
    if (flatsContent) flatsContent.scrollIntoView()
  }

  useEffect(() => {
    if (totalPages < activePage) {
      changePage(0)
    }
  }, [totalPages])

  let sliceMin = activePage > 4 ? activePage - 3 : 0
  const sliceMax = activePage > 4 ? activePage + 4 : 7

  sliceMin = activePage + 4 >= totalPages ? totalPages - 7 : sliceMin

  return (
    <Wrapper>
      {activePage !== 0 && (
        <PrevPageBtn
          onClick={() => changePage(activePage - 1)}
          aria-label="poprzednia strona"
        >
          <Icon size={15} src={arrowRightIcon} alt="arrow left" />
        </PrevPageBtn>
      )}
      <>
        {totalPages > 8 ? (
          <>
            {activePage > 4 && <Spacer />}
            {Array(totalPages)
              .fill()
              .map((_, i) => i)
              .slice(sliceMin, sliceMax)
              .map((page) => (
                <PageBtn
                  onClick={() => changePage(page)}
                  active={activePage === page}
                  key={page}
                >
                  {page + 1}
                </PageBtn>
              ))}
            {activePage + 4 < totalPages && <Spacer />}
          </>
        ) : (
          Array(totalPages)
            .fill()
            .map((_, i) => i)
            .map((page) => (
              <PageBtn
                onClick={() => changePage(page)}
                active={activePage === page}
                key={page}
              >
                {page + 1}
              </PageBtn>
            ))
        )}
      </>
      {activePage + 1 !== totalPages && (
        <NextPageBtn
          onClick={() => changePage(activePage + 1)}
          aria-label="następna strona"
        >
          <Icon size={15} src={arrowRightIcon} alt="arrow right" />
        </NextPageBtn>
      )}
    </Wrapper>
  )
}

Pagination.propTypes = {
  activePage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  setActivePage: PropTypes.func.isRequired,
  selector: PropTypes.string,
}

Pagination.defaultProps = {
  selector: '#browser',
}

export default Pagination
