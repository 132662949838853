// @ts-nocheck

import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'

import SwiperCore from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

import LazyImage from 'components/shared/lazyImage'
import Icon from 'components/shared/icon'

import rectangleLight from 'assets/icons/rectangle-light.svg'
import rectangleDark from 'assets/icons/rectangle-dark.svg'

import rectangleBtnLeft from 'assets/icons/rectangle-button-left.svg'
import rectangleBtnRight from 'assets/icons/rectangle-button-right.svg'

import closeIcon from 'assets/icons/close-dark.svg'

import type { Gallery } from 'components/layout/About/About1'

type Props = {
  gallery: Gallery
  visible: boolean
  close: () => void
  slideIndex: number
}

const SwiperOverlay = styled.div<{ visible: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99;

  opacity: 0;
  visibility: hidden;
  transition: 0.2s linear;

  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

const SwiperWrapper = styled.div`
  position: relative;
  width: 70%;
  z-index: 101;
`

const ControlButtons = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 91%;

  ${({ theme }) => theme.media.xxl.min} {
    width: 85%;
  }
`

const ControlButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const IndicatorButtons = styled.div`
  position: absolute;
  bottom: -90px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;

  ${({ theme }) => theme.media.lg.min} {
    bottom: -60px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    left: 50%;
  }
`

const IndicatorButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 6px;
`

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: -100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;

  transition: 0.2s linear;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray100};
  }
`

const ModalSwiper: React.FC<Props> = ({
  gallery,
  visible,
  close,
  slideIndex,
}) => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null)
  const [activeSlideIndex, setActiveSlideIndex] = useState(slideIndex)
  const [slideAnimationSpeed, setSlideAnimationSpeed] = useState(0)

  const goPrev = () => {
    swiper?.slidePrev()
  }

  const goNext = () => {
    swiper?.slideNext()
  }

  useEffect(() => {
    setActiveSlideIndex(slideIndex)

    if (visible) {
      setSlideAnimationSpeed(1000)
    } else {
      setSlideAnimationSpeed(0)
    }

    swiper?.slideTo(slideIndex)
  }, [visible])

  return (
    typeof window !== 'undefined' &&
    ReactDOM.createPortal(
      <SwiperOverlay visible={visible}>
        <SwiperWrapper>
          <Swiper
            initialSlide={activeSlideIndex}
            spaceBetween={0}
            slidesPerView={1}
            onSwiper={(initSwiper) => {
              setSwiper(initSwiper)
            }}
            // @ts-ignore
            onSlideChange={() => setActiveSlideIndex(swiper?.realIndex)}
            speed={slideAnimationSpeed}
          >
            {gallery.map(({ src, alt }, index) => (
              <SwiperSlide key={index}>
                <LazyImage src={src} alt={alt} />
              </SwiperSlide>
            ))}
          </Swiper>
          <IndicatorButtons>
            {gallery.map((i, index) => (
              <IndicatorButton
                key={`control-${index}`}
                type="button"
                onClick={() => {
                  setActiveSlideIndex(index)
                  swiper?.slideTo(index + 1)
                }}
              >
                <Icon
                  width={20}
                  src={
                    activeSlideIndex === index ? rectangleLight : rectangleDark
                  }
                  alt="rectangle"
                />
              </IndicatorButton>
            ))}
          </IndicatorButtons>
          <CloseButton onClick={close}>
            <Icon src={closeIcon} size={25} alt="close" />
          </CloseButton>
        </SwiperWrapper>
        <ControlButtons>
          <ControlButton onClick={goPrev}>
            <Icon src={rectangleBtnLeft} width={85} alt="rectangle-btn" />
          </ControlButton>
          <ControlButton onClick={goNext}>
            <Icon src={rectangleBtnRight} width={85} alt="rectangle-btn" />
          </ControlButton>
        </ControlButtons>
      </SwiperOverlay>,
      document.querySelector('body')!,
    )
  )
}

export default ModalSwiper
