const getUniqueFlatLayouts = (flats) => {
  let uniqueLayouts = []
  if (flats) {
    flats.forEach((flat) => {
      if (flat.similarFlats) {
        const isAlreadyIncluded = uniqueLayouts.some(({ similarFlats }) =>
          similarFlats.map((el) => el.flat).includes(flat.name),
        )
        if (!isAlreadyIncluded) {
          uniqueLayouts = [...uniqueLayouts, flat]
        }
      }
    })
  }
  return uniqueLayouts.length === 0 ? flats : uniqueLayouts
}

export default getUniqueFlatLayouts
