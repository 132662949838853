/* eslint-disable */
import { useState, useEffect, useContext } from 'react'
import sortBy from 'browser/utils/sortBy'
import useMedia from 'browser/hooks/useMedia'
import useFilters from 'browser/hooks/useFilters'
import getUniqueFlatLayouts from 'browser/utils/getUniqueFlatLayouts'
import { store } from 'browser/store'

const toggleBodyOverflow = (modalVisible) => {
  if (typeof window !== `undefined`) {
    if (modalVisible) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }
}

const useFlats = () => {
  const [formState, setFormState] = useState({
    visible: false,
    name: null,
    investment: null,
    bookFlat: false,
  })
  const [selectedFloorsText, setSelectedFloorsText] = useState('')
  const [saleOnly, setSaleOnly] = useState(false)
  const [activePage, setActivePage] = useState(0)
  const [viewType, setViewType] = useState('list')
  const [sortProperty, setSortProperty] = useState(null)
  const [flatsPerView, setFlatsPerView] = useState(4)
  const [pages, setPages] = useState([])
  const [tour3DState, setTour3DState] = useState({
    src: null,
    flat: null,
  })

  const {
    state: { flats, allFlats, filters, favourites },
    dispatch,
  } = useContext(store)

  const filteredFlats = flats.filter(
    ({ status, isPriceVisible }) =>
      status !== 3 && (saleOnly ? !isPriceVisible : true),
  )

  const updateFlats = (newFlats) => {
    dispatch({
      type: 'UPDATE_FLATS',
      payload: newFlats,
    })
  }

  const updateFilters = (property, value) => {
    if (typeof property === 'object') {
      dispatch({
        type: 'UPDATE_FILTERS',
        payload: property,
      })
    } else
      dispatch({
        type: 'UPDATE_FILTERS',
        payload: { ...filters, [property]: value },
      })
  }

  const {
    handleAreaMin,
    handleAreaMax,
    handlePriceMin,
    handlePriceMax,
    handleStatusesMultiSelect,
    handleStagesMultiSelect,
    handleRoomsMultiSelect,
    handleFloorMultiSelect,
    handleAreaRange,
    handlePriceRange,
  } = useFilters({
    allFlats,
    filters,
    updateFlats,
    updateFilters,
  })

  const matchesLG = useMedia('lg')

  const showTour3D = (tourSrc, flatName) => {
    setTour3DState({
      src: tourSrc,
      flat: flatName,
    })
  }

  const handleSetViewType = (type) => {
    setViewType(type)
    setActivePage(0)
  }

  const handleSetSortProperty = (property) => {
    setSortProperty(property)
    setActivePage(0)
  }

  const onButtonClick = (name) => {
    if (favourites.includes(name)) {
      dispatch({ type: 'REMOVE_FROM_FAVOURITES', payload: name })
    } else {
      dispatch({ type: 'ADD_TO_FAVOURITES', payload: name })
    }
  }

  const updatePages = (passedFlats = filteredFlats) => {
    const flatsToView =
      viewType === 'cards' ? getUniqueFlatLayouts(passedFlats) : passedFlats

    // If viewType === 'cards' filter out all sold flats
    const newPages =
      viewType === 'cards'
        ? flatsToView
            .filter((flat) => flat.status !== 0)
            .map((_, i, all) =>
              all.slice(i * flatsPerView, (i + 1) * flatsPerView),
            )
            .filter((x) => x.length)
        : flatsToView
            .map((_, i, all) =>
              all.slice(i * flatsPerView, (i + 1) * flatsPerView),
            )
            .filter((x) => x.length)

    setPages(newPages)
  }

  const toggleForm = ({ name, investment }, bookFlat = false) => {
    toggleBodyOverflow(!formState.visible)
    setFormState({
      visible: !formState.visible,
      name,
      investment,
      bookFlat,
    })
  }

  const closeForm = () => {
    setFormState({
      ...formState,
      visible: false,
    })
    toggleBodyOverflow(false)
  }

  useEffect(() => {
    if (sortProperty) {
      const { property, reverse } = sortProperty
      let newSortedFlats = filteredFlats.sort(sortBy(property, reverse))

      // sort - price on sale first
      newSortedFlats = newSortedFlats.sort((a, b) => !b.isPriceVisible)

      updateFlats(newSortedFlats)
      updatePages(newSortedFlats)
    }
    if (pages.length && pages.length < activePage) setActivePage(0)
  }, [flatsPerView, sortProperty, allFlats.length])

  useEffect(() => {
    if (viewType === 'cards') {
      setFlatsPerView(4)
      matchesLG && setFlatsPerView(6)
    } else if (viewType === 'list') setFlatsPerView(10)
  }, [matchesLG, viewType])

  const firstFloor = filters.floors.all[0]
  const lastFloor = filters.floors.all[filters.floors.all.length - 1]
  const uniqueLayoutsNum = getUniqueFlatLayouts(filteredFlats).length

  useEffect(() => {
    updatePages()
    if (firstFloor) {
      setSelectedFloorsText(
        filters.floors.selected.length === 0
          ? ` od ${firstFloor} do ${lastFloor}`
          : `: ${filters.floors.selected.sort((a, b) => a - b).join(', ')}`,
      )
    }
  }, [filteredFlats.length, flatsPerView])

  return {
    formState,
    tour3DState,
    filters,
    allFlats,
    saleOnly,
    filteredFlats,
    selectedFloorsText,
    firstFloor,
    lastFloor,
    viewType,
    pages,
    activePage,
    sortProperty,
    uniqueLayoutsNum,
    handleSetViewType,
    setSaleOnly,
    showTour3D,
    setTour3DState,
    handleSetSortProperty,
    updateFlats,
    onButtonClick,
    setActivePage,
    closeForm,
    toggleForm,
    handleStatusesMultiSelect,
    handleStagesMultiSelect,
    handleRoomsMultiSelect,
    handleFloorMultiSelect,
    handleAreaMin,
    handleAreaMax,
    handlePriceMin,
    handlePriceMax,
    handleAreaRange,
    handlePriceRange,
  }
}

export default useFlats
