import React from 'react'
import styled from 'styled-components'

import { Heading, Text } from 'components/shared/typography'
import LazyImage from 'components/shared/lazyImage'

import { ImageDataLike } from 'gatsby-plugin-image'

import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  titleMobile: string
  titleDesktop: string
  descriptionMobile: string
  descriptionDesktop: string
  img: ImageDataLike
  cloudImg: ImageDataLike
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 140px;
  overflow: hidden;
  position: relative;

  & > :last-child {
    align-self: center;
  }

  ${({ theme }) => theme.media.lg.min} {
    width: 42.5%;
    padding-right: min(3.75vw, 25px);
    margin-top: 30px;
  }
`

const ImgWrapper = styled.div`
  align-self: center;
`

const CloudWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-40%, 46%);
  width: 1000px;
  z-index: 1;

  ${({ theme }) => theme.media.sm.min} {
    transform: translate(-46%, 34%);
  }

  ${({ theme }) => theme.media.lg.min} {
    width: 140%;
    max-width: 850px;
    transform: translate(-60%, 34%);
  }
`

const About2: React.FC<Props> = ({
  titleMobile,
  titleDesktop,
  descriptionMobile,
  descriptionDesktop,
  img,
  cloudImg,
}) => {
  const { lg } = useBreakpoint()

  return (
    <Wrapper>
      <Heading
        size={42}
        weight={500}
        margin="40px"
        themecolor="dark100"
        dangerouslySetInnerHTML={{ __html: lg ? titleDesktop : titleMobile }}
      />
      <Text
        size={16}
        line={2}
        themecolor="dark100"
        dangerouslySetInnerHTML={{
          __html: lg ? descriptionDesktop : descriptionMobile,
        }}
      />
      <ImgWrapper>
        <LazyImage src={img} alt="map-plan" />
      </ImgWrapper>
      <CloudWrapper>
        <LazyImage src={cloudImg} alt="cloud" />
      </CloudWrapper>
    </Wrapper>
  )
}

export default About2
