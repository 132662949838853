import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { LangContext } from 'browser/contexts/LangContext'
import { useHistory } from 'react-router-dom'
import { store } from 'browser/store'
import { navigate } from 'gatsby'
// import Icon from 'browser/components/Icon'
// import closeIcon from 'browser/assets/icons/close.svg'
// import heartIcon from 'browser/assets/icons/heart.svg'
// import fullHeartIcon from 'browser/assets/icons/full-heart.svg'

const TableWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-right: 150px;
  max-width: 100vw;
  ${({ theme }) => theme.mq.lg} {
    max-width: calc(100vw - 400px);
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 150px;
    width: 25px;
    height: 100%;
    background: linear-gradient(88deg, #0000000d, #00000000);
  }
  @media (min-width: 1360px) {
    padding-right: 0;
    &:after {
      content: none;
    }
  }
`

const TableInnerWrapper = styled.div`
  width: 100%;
  margin-left: 150px;
  overflow-x: auto;
  overflow-y: visible;
  @media (min-width: 1360px) {
    margin-left: 0;
    overflow-x: visible;
  }
`

const Wrapper = styled.table`
  border-collapse: separate;
  width: 100%;
  margin-bottom: 10px;
  td,
  th {
    position: relative;
    padding: 15px 20px;
    white-space: nowrap;
    border-bottom: 1px solid #ebebeb;
    &:first-of-type {
      padding-left: 20px;
      position: absolute;
      left: 0;
      width: 150px;
      @media (min-width: 1360px) {
        position: static;
        width: auto;
        left: auto;
      }
    }
    @media (min-width: 1360px) {
      padding: 20px 25px;
    }
  }
  td {
    &:first-of-type {
      padding-bottom: 17px;
      @media (min-width: 1360px) {
        padding-bottom: 20px;
      }
    }
  }
  th {
    text-align: left;
    background-color: ${({ theme }) => theme.colors.white100};
  }
`

const HeadingTR = styled.tr`
  th {
    padding: 20px 25px;
    line-height: 45px;
    &:last-child {
      width: 100px;
    }
    span {
      position: relative;
    }
  }
`

const TableRow = styled.tr`
  transition: 0.2s;
  color: ${({ theme }) => theme.colors.dark300};
  box-shadow: none;
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'auto')};
  @media (hover: hover) {
    &:hover {
      background-color: #f5f5f5;
      color: ${({ theme }) => theme.colors.dark};
      box-shadow: 0px 6px 12px #00000012;
    }
  }
`

const Empty = styled.span`
  padding-left: 12px;
`

const OptionButton = styled.button`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  cursor: pointer;
  padding: 0;
  svg {
    width: 18px;
    height: 18px;
  }
`

const SortButtons = styled.div`
  position: absolute;
  right: -18px;
  top: 4px;
  display: flex;
  flex-direction: column;
`

const SortBtnUp = styled.button`
  width: 0px;
  height: 0px;
  padding: 0;
  background: transparent;
  cursor: pointer;
  border-style: solid;
  border-width: 0 6px 6px;
  border-color: transparent transparent
    ${({ theme, active }) =>
      active ? theme.colors.primary : theme.colors.gray};
`

const SortBtnDown = styled(SortBtnUp)`
  border-width: 6px 6px 0;
  border-color: ${({ theme, active }) =>
      active ? theme.colors.primary : theme.colors.gray}
    transparent transparent;
  margin-top: 4px;
`

const PromoLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  padding: 5px 8px;
  background: ${({ theme }) => theme.colors.primary};
  color: #fff;
  max-width: max-content;
  font-weight: 700;
  font-size: 1.2rem;
  border-radius: 4px;
  min-width: 80px;
`

const StatusLabel = styled.span`
  color: #25c925;

  ${({ sold }) =>
    sold &&
    css`
      color: #ff0000;
    `}

  ${({ reserved }) =>
    reserved &&
    css`
      color: #ffa500;
    `}
`

const Table = ({
  flats,
  columns,
  sortProperty,
  setSortProperty,
  onButtonClick,
  favourites,
}) => {
  const history = useHistory()
  const { translate } = useContext(LangContext)
  const {
    state: { favourites: favouriteFlats },
  } = useContext(store)
  const allProperties = columns.map(({ property }) => property)

  const checkIsFavourite = (name) => {
    return favouriteFlats.includes(name)
  }

  return (
    <TableWrapper>
      <TableInnerWrapper>
        <Wrapper>
          <thead>
            <HeadingTR>
              {columns.map(({ label, property }) => (
                <th key={label}>
                  <span>
                    {label}
                    <SortButtons>
                      <SortBtnUp
                        onClick={() =>
                          setSortProperty({ property, reverse: false })
                        }
                        active={
                          sortProperty &&
                          sortProperty.property === property &&
                          !sortProperty.reverse
                        }
                        aria-label={`sortuj ${property} rosnąco`}
                      />
                      <SortBtnDown
                        active={
                          sortProperty &&
                          sortProperty.property === property &&
                          sortProperty.reverse
                        }
                        onClick={() =>
                          setSortProperty({ property, reverse: true })
                        }
                        aria-label={`sortuj ${property} malejąco`}
                      />
                    </SortButtons>
                  </span>
                </th>
              ))}
              {/* <th> </th> */}
            </HeadingTR>
          </thead>
          <tbody>
            {flats &&
              flats.map((flat, index) => {
                const {
                  investment,
                  name,
                  stage,
                  rooms,
                  area,
                  floor,
                  balcony,
                  status,
                  garden,
                  price,
                  isPriceVisible,
                } = flat

                return (
                  <TableRow
                    pointer={status !== 3}
                    onClick={(e) => {
                      navigate(`/mieszkanie/${stage}/${name}`)
                    }}
                    key={name + investment + index}
                  >
                    {allProperties.includes('investment') && (
                      <td>{investment}</td>
                    )}
                    {allProperties.includes('name') && <td>{name}</td>}
                    {stage && <td>{stage}</td>}
                    {area && (
                      <td>
                        {Number(area).toFixed(2)} m<sup>2</sup>
                      </td>
                    )}
                    {allProperties.includes('floor') && <td>{floor}</td>}
                    {allProperties.includes('rooms') && <td>{rooms}</td>}
                    {allProperties.includes('balcony') && (
                      <td>
                        {Number(balcony) ? (
                          <>
                            {Number(balcony).toFixed(2)} m<sup>2</sup>
                          </>
                        ) : (
                          <Empty>-</Empty>
                        )}
                      </td>
                    )}
                    {allProperties.includes('garden') && (
                      <td>
                        {Number(garden) ? (
                          <>
                            {Number(garden)} m<sup>2</sup>
                          </>
                        ) : (
                          <Empty>-</Empty>
                        )}
                      </td>
                    )}
                    {/* {allProperties.includes('price') && (
                      <td>
                        {isPriceVisible && Number(price) ? (
                          <>
                            {price
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}{' '}
                            PLN
                          </>
                        ) : (
                          <>-</>
                        )}
                      </td>
                    )} */}
                    {allProperties.includes('status') && (
                      <td>
                        {status === 0 && (
                          <StatusLabel
                            sold
                            dangerouslySetInnerHTML={{
                              __html: translate('status_sold'),
                            }}
                          />
                        )}
                        {status === 1 && (
                          <StatusLabel
                            reserved
                            dangerouslySetInnerHTML={{
                              __html: translate('status_reserved'),
                            }}
                          />
                        )}
                        {status === 2 && (
                          <StatusLabel
                            dangerouslySetInnerHTML={{
                              __html: translate('status_available'),
                            }}
                          />
                        )}
                      </td>
                    )}
                    {/* <td>
                      <OptionButton onClick={() => onButtonClick(name)}>
                        {favourites ? (
                          <Icon src={closeIcon} alt="delete" size={18} />
                        ) : (
                          <>
                            {checkIsFavourite(name) ? (
                              <Icon
                                src={fullHeartIcon}
                                alt="full heart"
                                size={18}
                              />
                            ) : (
                              <Icon src={heartIcon} alt="heart" size={18} />
                            )}
                          </>
                        )}
                      </OptionButton>
                    </td> */}
                  </TableRow>
                )
              })}
          </tbody>
        </Wrapper>
      </TableInnerWrapper>
    </TableWrapper>
  )
}

Table.propTypes = {
  sortProperty: PropTypes.objectOf(PropTypes.string),
  flats: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSortProperty: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func,
  favourites: PropTypes.bool,
}

Table.defaultProps = {
  sortProperty: null,
  onButtonClick: () => null,
  favourites: false,
}

export default Table
