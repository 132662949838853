/* eslint-disable prefer-spread */
import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import useOutsideClick from 'browser/hooks/useOutsideClick'
import Icon from 'browser/components/Icon'
import dropIcon from 'assets/icons/arrow-light.svg'
import checkIcon from 'browser/assets/icons/confirm.svg'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: ${({ first }) => (first ? '9' : '8')};
  transition: 0.2s;
  margin-bottom: 20px;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
  ${({ small }) =>
    small &&
    css`
      max-width: 150px;
    `}
`

const Dropdown = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 0;
  height: 50px;
  transition: 0.3s;
  background-color: transparent;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.light};
  user-select: none;
  ${({ small }) =>
    small &&
    css`
      border: none;
      padding: 12px 10px !important;
    `}
`

const Label = styled.span`
  color: ${({ theme }) => theme.colors.gray};
  display: none;
  font-size: 1.2rem;
  opacity: 0.75;

  ${({ theme }) => theme.mq.sm} {
    position: absolute;
    left: 0;
    top: -15px;
  }

  ${({ visible }) =>
    visible &&
    css`
      display: block;
    `}
`

const Name = styled.span`
  font-size: 1.2rem;
  opacity: 0.75;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5px;
  color: ${({ theme }) => theme.colors.gray};
  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      font-weight: ${({ theme }) => theme.semiBold};
    `}
`

const Options = styled.ul`
  position: absolute;
  top: calc(100% - 1px);
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSize.s};
  transition: 0.15s;
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  max-height: 250px;
  overflow-y: auto;
`

const Option = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 35px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.2s;
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.primary : theme.colors.dark};
  @media (hover: hover) {
    &:hover {
      background: ${({ theme }) => theme.colors.primaryLight};
    }
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`

const Checkmark = styled.span`
  position: absolute;
  top: 13px;
  left: 10px;
  height: 16px;
  width: 16px;
  border: 1px solid;
  border-color: ${({ checked, theme }) =>
    checked ? theme.colors.primary : theme.colors.gray100};
  background: ${({ checked, theme }) =>
    checked ? theme.colors.primary : 'transparent'};
  border-radius: 4px;
  transition: 0.2s;
  &:after {
    content: '';
    position: absolute;
    display: ${({ checked }) => (checked ? 'block' : 'none')};
    left: 2px;
    top: 2px;
    width: 10px;
    height: 10px;
    background: url(${checkIcon}) no-repeat center;
  }
`

const Select = ({
  disabled,
  handleSelect,
  placeholder,
  allOptions,
  selectedOptions,
  disabledOptions,
  first,
  multiSelect,
  small,
}) => {
  const [areOptionsVisible, setOptionsVisibility] = useState(false)

  const selectRef = useRef(null)
  const optionsRef = useRef(null)
  useOutsideClick(optionsRef, setOptionsVisibility, selectRef)

  const toggleOptionsVisibility = () => {
    setOptionsVisibility(!areOptionsVisible)
  }

  const handleOptionClick = (option) => {
    if (!multiSelect) {
      toggleOptionsVisibility()
    }
    handleSelect(option)
  }

  const realPlaceholder = multiSelect
    ? selectedOptions.map((option) => option).join(', ')
    : selectedOptions[0]

  return (
    <Wrapper small={small} first={first} disabled={disabled}>
      <Label visible={selectedOptions.length && realPlaceholder}>
        {placeholder}
      </Label>
      <Dropdown
        small={small}
        isActive={areOptionsVisible}
        ref={selectRef}
        onClick={toggleOptionsVisibility}
      >
        <Name active={selectedOptions.length && realPlaceholder}>
          {(selectedOptions.length && realPlaceholder) || placeholder}
        </Name>

        <Icon src={dropIcon} alt="drop down" rotate={90} auto />
      </Dropdown>
      <Options ref={optionsRef} isVisible={areOptionsVisible}>
        {allOptions &&
          allOptions.map((option) => (
            <Option
              disabled={disabledOptions.includes(option)}
              onClick={() => handleOptionClick(option)}
              key={option}
            >
              <Checkmark checked={selectedOptions.includes(option)} />
              {option}
            </Option>
          ))}
      </Options>
    </Wrapper>
  )
}

Select.propTypes = {
  disabled: PropTypes.bool,
  first: PropTypes.bool,
  small: PropTypes.bool,
  multiSelect: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  allOptions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  selectedOptions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  disabledOptions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
}

Select.defaultProps = {
  first: false,
  disabled: false,
  multiSelect: false,
  small: false,
  disabledOptions: [],
}

export default Select
