import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { MemoryRouter, Route } from 'react-router-dom'
import { Provider as KeepAliveProvider } from 'react-keep-alive'
import theme from 'browser/styled/theme'

// import Browser from 'browser/Browser'
import FlatsBrowser from 'browser/components/FlatsBrowser/Flats'
import Loader from 'browser/components/Loader'

import { Provider as StoreProvider } from 'browser/store'
import LangProvider from 'browser/contexts/LangContext'

// const Wrapper = styled.div`
//   position: relative;
//   width: 100%;
//   height: ${({ full }) => (full ? '100vh' : 'calc(120vw + 65px)')};
//   overflow: hidden;
//   background: #efefef;
//   ${({ auto }) =>
//     auto &&
//     css`
//       height: 100% !important;
//     `}
//   @media (min-width: 992px) {
//     height: 100vh !important;
//   }
// `

const App = ({ investment, allFlats, lang, isLoading, loadingText }) => {
  const initialRoute = useMemo(
    () =>
      typeof sessionStorage !== 'undefined'
        ? sessionStorage.getItem(`url-${investment.url}`)
        : false,
    [investment],
  )

  return (
    <MemoryRouter initialEntries={[initialRoute || '/']}>
      <Route>
        <LangProvider initLang={lang}>
          <ThemeProvider theme={theme}>
            {allFlats && !isLoading && (
              <StoreProvider
                outerState={{
                  investment,
                  allFlats,
                }}
              >
                <KeepAliveProvider>
                  {/* <Wrapper full={investment.multiBuilding} auto={!isLoading}>
                    <Browser />
                    <Loader withBg visible={isLoading} text={loadingText} />
                  </Wrapper> */}
                  <FlatsBrowser />
                </KeepAliveProvider>
              </StoreProvider>
            )}
            <Loader withBg visible={isLoading} text={loadingText} />
          </ThemeProvider>
        </LangProvider>
      </Route>
    </MemoryRouter>
  )
}

App.propTypes = {
  investment: PropTypes.objectOf(PropTypes.any).isRequired,
  loadingText: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  allFlats: PropTypes.arrayOf(PropTypes.object),
  lang: PropTypes.oneOf(['pl', 'en']),
}

App.defaultProps = {
  allFlats: [],
  lang: 'pl',
}

export default App
