import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import LazyImage from 'components/shared/lazyImage'
import Icon from 'components/shared/icon'

import { ImageDataLike } from 'gatsby-plugin-image'

import rectangleDark from 'assets/icons/rectangle-dark.svg'
import rectangleLight from 'assets/icons/rectangle-light.svg'

import Button from 'components/shared/button'
import { Text } from 'components/shared/typography'

type StageObj = {
  stageActive: string
  stageTitle: string
}

type Image = {
  src: ImageDataLike
  alt: string
  date: string
}

type Props = {
  gallery: Image[]
  stages: StageObj[]
  initialImagesCount: number
}

const Wrapper = styled.div`
  overflow: hidden;
`

const StageMainLine = styled.div`
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  width: calc(100% - 64px);
  background-color: ${({ theme }) => theme.colors.dark100};
  z-index: 1;
`

const ImagesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;

  ${({ theme }) => theme.media.xxl.min} {
    gap: 50px;
  }
`

const ImageWrapperOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000048;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;

  transition: 0.2s;
`

const ImageWrapper = styled.div`
  position: relative;

  &:hover ${ImageWrapperOverlay} {
    opacity: 1;
  }
`

const Stages = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 60px 0;

  & > :first-child {
    padding-left: 20px;
  }

  & > :last-child {
    padding-right: 20px;
  }
`

const Stage = styled.div<{ active: boolean }>`
  position: relative;
  color: ${({ theme }) => theme.colors.gray};
  font-size: 14px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 150%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.dark100};
    z-index: 2;
  }

  ${({ theme }) => theme.media.xl.min} {
    font-size: 16px;
  }

  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.secondary};

      &:after {
        background-color: ${({ theme }) => theme.colors.light};
      }
    `}
`

const ActiveButtonIcons = styled.div`
  position: absolute;
  top: -45px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;

  & > div {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  & > div:first-child {
    top: -5px;
  }

  & > div:last-child {
    top: -15px;
  }
`

const ShowMoreButton = styled(Button)`
  display: block;
  width: 220px;
  height: 55px;
  margin: 50px auto 0;
`

const Gallery: React.FC<Props> = ({ gallery, stages, initialImagesCount }) => {
  const [displayedImages, setDisplayedImages] = useState<Image[] | []>(gallery)

  useEffect(() => {
    setDisplayedImages(gallery.slice(0, initialImagesCount))
  }, [stages])

  const handleShowMoreImages = () => {
    setDisplayedImages(
      gallery.slice(0, displayedImages.length + initialImagesCount),
    )
  }

  return (
    <Wrapper>
      <StageMainLine />
      <Stages>
        {stages.map(({ stageActive, stageTitle }) => (
          <Stage key={stageTitle} active={!!stageActive}>
            {stageTitle}
            {stageActive && (
              <ActiveButtonIcons>
                <Icon src={rectangleLight} size={20} alt="rectangle" />
                <Icon src={rectangleDark} size={20} alt="rectangle" />
              </ActiveButtonIcons>
            )}
          </Stage>
        ))}
      </Stages>
      <ImagesWrapper>
        {displayedImages.map(({ src, alt, date }, index) => (
          <ImageWrapper key={`${alt}-${index}`}>
            <LazyImage src={src} alt={alt} />
            <ImageWrapperOverlay>
              <Text
                size={16}
                weight={600}
                themecolor="white"
                dangerouslySetInnerHTML={{ __html: date }}
              />
            </ImageWrapperOverlay>
          </ImageWrapper>
        ))}
      </ImagesWrapper>
      {displayedImages.length < gallery.length && (
        <ShowMoreButton onClick={handleShowMoreImages}>
          Pokaż więcej
        </ShowMoreButton>
      )}
    </Wrapper>
  )
}

export default Gallery
