import { useEffect, useContext } from 'react'
import { LangContext } from 'browser/contexts/LangContext'
const useFilters = ({
  allFlats,
  filters,
  currentFloor = null,
  updateFlats,
  updateFilters,
  rangeSliders = true,
}) => {
  const { translate } = useContext(LangContext)

  const applyFilters = () => {
    let newFlats = allFlats
    const { statuses, stages, rooms, floors, area, price } = filters
    if (statuses) {
      if (
        filters.statuses.selected.filter((status) =>
          filters.statuses.all.includes(status),
        ).length !== 0
      ) {
        newFlats = newFlats.filter((flat) => {
          let flatStatusLabel = ''

          if (flat.status === 0) {
            flatStatusLabel = translate('status_sold')
          }
          if (flat.status === 1) {
            flatStatusLabel = translate('status_reserved')
          }
          if (flat.status === 2) {
            flatStatusLabel = translate('status_available')
          }

          return filters.statuses.selected.includes(flatStatusLabel)
        })
      }
    }

    if (stages) {
      if (
        filters.stages.selected.filter((stage) =>
          filters.stages.all.includes(stage),
        ).length !== 0
      ) {
        const undergroundParkingSelected =
          filters.stages.selected.includes('Parking podziemny')

        if (!undergroundParkingSelected) {
          newFlats = newFlats.filter((flat) =>
            filters.stages.selected.includes(flat.stage),
          )
        } else {
          const temporaryStages = filters.stages.selected.filter(
            (stage) => stage !== 'Parking podziemny',
          )

          if (temporaryStages.length > 0) {
            newFlats = newFlats.filter((flat) =>
              temporaryStages.includes(flat.stage),
            )
          }
          newFlats = newFlats.filter(
            (flat) => flat.type === 'Miejsce parkingowe',
          )
        }
      }
    }
    if (rooms) {
      if (
        filters.rooms.selected.filter((room) =>
          filters.rooms.all.includes(room),
        ).length !== 0
      ) {
        newFlats = newFlats.filter((flat) =>
          filters.rooms.selected.includes(flat.rooms),
        )
      }
    }
    if (!currentFloor && floors) {
      if (
        filters.floors.selected.filter((el) => filters.floors.all.includes(el))
          .length !== 0
      ) {
        newFlats = newFlats.filter((flat) =>
          filters.floors.selected.includes(flat.floor),
        )
      }
    }
    const areaMin = Number(filters.area.values[0])
    const areaMax = Number(filters.area.values[1])
    if (area && (areaMin || areaMax)) {
      newFlats = newFlats.filter((flat) => {
        if (areaMin && !areaMax) {
          return Number(flat.area) >= areaMin
        }
        if (areaMax && !areaMin) {
          return Number(flat.area) <= areaMax
        }
        return Number(flat.area) >= areaMin && Number(flat.area) <= areaMax
      })
    }
    const priceMin = Number(filters.price.values[0])
    const priceMax = Number(filters.price.values[1])
    if (price && (priceMin || priceMax)) {
      newFlats = newFlats.filter((flat) => {
        if (priceMin && !priceMax) {
          return Number(flat.price) >= priceMin
        }
        if (priceMax && !priceMin) {
          return Number(flat.price) <= priceMax
        }
        return Number(flat.price) >= priceMin && Number(flat.price) <= priceMax
      })
    }
    // sort - price on sale first
    newFlats = newFlats.sort((a, b) => !b.isPriceVisible)

    updateFlats(newFlats)
  }

  const handleAreaMin = (value) => {
    if (
      value === '00' ||
      Math.abs(value) > 1000 ||
      (!value.match(/^[0-9]*$/) && value !== '')
    )
      return
    updateFilters('area', {
      values: [value, filters.area.values[1]],
    })
  }

  const handleAreaMax = (value) => {
    if (
      value === '00' ||
      Math.abs(value) > 1000 ||
      (!value.match(/^[0-9]*$/) && value !== '')
    )
      return
    updateFilters('area', {
      values: [filters.area.values[0], value],
    })
  }

  const handleAreaRange = (values) => {
    updateFilters('area', {
      ...filters.area,
      values,
    })
  }

  const handlePriceMin = (value) => {
    if (
      value === '00' ||
      Math.abs(value) > 10000000 ||
      (!value.match(/^[0-9]*$/) && value !== '')
    )
      return
    updateFilters('price', {
      values: [value, filters.price.values[1]],
    })
  }

  const handlePriceMax = (value) => {
    if (
      value === '00' ||
      Math.abs(value) > 10000000 ||
      (!value.match(/^[0-9]*$/) && value !== '')
    )
      return
    updateFilters('price', {
      values: [filters.price.values[0], value],
    })
  }

  const handlePriceRange = (values) => {
    updateFilters('price', {
      ...filters.price,
      values,
    })
  }

  const handleStatusesMultiSelect = (option) => {
    const { statuses } = filters

    if (statuses.selected.includes(option))
      updateFilters('statuses', {
        ...statuses,
        selected: statuses.selected.filter((item) => item !== option),
      })
    else {
      updateFilters('statuses', {
        ...statuses,
        selected: [...statuses.selected, option],
      })
    }
  }

  const handleStagesMultiSelect = (option) => {
    const { stages } = filters

    if (stages.selected.includes(option))
      updateFilters('stages', {
        ...stages,
        selected: stages.selected.filter((item) => item !== option),
      })
    else {
      updateFilters('stages', {
        ...stages,
        selected: [...stages.selected, option],
      })
    }
  }

  const handleRoomsMultiSelect = (option) => {
    const { rooms } = filters
    if (rooms.selected.includes(option))
      updateFilters('rooms', {
        ...rooms,
        selected: rooms.selected.filter((item) => item !== option),
      })
    else {
      updateFilters('rooms', {
        ...rooms,
        selected: [...rooms.selected, option],
      })
    }
  }

  const handleFloorMultiSelect = (option) => {
    const { floors } = filters
    if (floors.selected.includes(option))
      updateFilters('floors', {
        ...floors,
        selected: floors.selected.filter((item) => item !== option),
      })
    else {
      updateFilters('floors', {
        ...floors,
        selected: [...floors.selected, option],
      })
    }
  }

  useEffect(() => {
    const uniqueStatuses = [
      ...new Set(
        allFlats
          .filter(({ status }) => status || status === 0)
          .map(({ status }) => {
            if (status === 0) {
              return translate('status_sold')
            }
            if (status === 1) {
              return translate('status_reserved')
            }
            if (status === 2) {
              return translate('status_available')
            }
          })
          .sort((a, b) => a - b),
      ),
    ]
    const uniqueStages = [
      ...new Set(
        allFlats
          .filter(({ stage }) => stage)
          .map(({ stage }) => stage)
          .sort((a, b) => a - b),
      ),
      'Parking podziemny',
    ]

    const uniqueFloors = [
      ...new Set(
        allFlats
          .filter(({ floor }) => floor || floor === 0)
          .map(({ floor }) => floor)
          .sort((a, b) => a - b),
      ),
    ]

    const uniqueRooms = [
      ...new Set(
        allFlats
          .filter(({ rooms }) => rooms)
          .map(({ rooms }) => rooms)
          .sort((a, b) => a - b),
      ),
    ]
    const minArea = Math.min.apply(
      null,
      allFlats.map(({ area }) => Math.floor(area)),
    )
    const maxArea = Math.max.apply(
      null,
      allFlats.map(({ area }) => Math.ceil(area)),
    )
    const minPrice = Math.min.apply(
      null,
      allFlats.map(({ price }) => Math.floor(price)),
    )
    const maxPrice = Math.max.apply(
      null,
      allFlats.map(({ price }) => Math.ceil(price)),
    )

    let newFilters = {
      ...filters,
      statuses: { all: uniqueStatuses, selected: filters.statuses.selected },
      stages: { all: uniqueStages, selected: filters.stages.selected },
      rooms: { all: uniqueRooms, selected: filters.rooms.selected },
      floors: { all: uniqueFloors, selected: filters.floors.selected },
    }
    if (rangeSliders) {
      newFilters = {
        ...newFilters,
        area: { extremes: [minArea, maxArea], values: [minArea, maxArea] },
        price: {
          extremes: [minPrice, maxPrice],
          values: [minPrice, maxPrice],
        },
      }
    }
    updateFilters(newFilters)
  }, [allFlats])

  useEffect(() => {
    applyFilters()
  }, [
    filters.price.values,
    filters.area.values,
    filters.stages,
    filters.statuses,
    filters.rooms,
    filters.floors,
    currentFloor,
  ])

  return {
    handleAreaMin,
    handleAreaMax,
    handleAreaRange,
    handlePriceMin,
    handlePriceMax,
    handlePriceRange,
    handleStatusesMultiSelect,
    handleStagesMultiSelect,
    handleRoomsMultiSelect,
    handleFloorMultiSelect,
  }
}

export default useFilters
