import React, { useEffect } from 'react'
import styled from 'styled-components'

import LazyImage from 'components/shared/lazyImage'
import Icon from 'components/shared/icon'
import Container from 'components/shared/container'
import { Heading } from 'components/shared/typography'

import heroVideo from 'assets/images/dolina-sanu_hero.mp4'
import patternIcon from 'assets/icons/pattern-1.svg'
import rectangleLight from 'assets/icons/rectangle-light.svg'
import arrowDark from 'assets/icons/arrow-dark.svg'

import scrollToSection from 'utils/scrollToSection'

import { ImageDataLike } from 'gatsby-plugin-image'

import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  title: string
  subtitle: string
  cloudImg: ImageDataLike
}

const StyledHeader = styled.header`
  position: relative;
  overflow: hidden;
`

const StyledContainer = styled(Container)`
  position: absolute;
  top: 250px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 0;
  z-index: 1;

  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 75%;
    max-width: 1240px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

const VideoWrapper = styled.div`
  width: 100%;
  video {
    max-width: 1550px;
  }

  ${({ theme }) => theme.media.lg.min} {
    height: 805px;
    video {
      max-width: 1920px;
    }
  }

  ${({ theme }) => theme.media.xxl.min} {
    height: 900px;
  }

  ${({ theme }) => theme.media.xxxl.min} {
    video {
      width: 100%;
      max-width: unset;
    }
  }
`

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`

const StyledHeading = styled(Heading)`
  font-family: 'HelveticaNeueLight', sans-serif;
`

const PatternWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.media.lg.min} {
    width: 20%;
    max-width: 210px;
  }
`

const IconButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;

  top: 25%;
  left: 50%;
  transform: translateX(-50%);

  // Arrow Icon
  & > :last-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${({ theme }) => theme.media.lg.min} {
    top: unset;
    bottom: 5%;
  }
`

const CloudWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-20%, 52%);
  width: 1600px;
  z-index: 2;

  ${({ theme }) => theme.media.sm.min} {
    transform: translate(-36%, 57%);
  }

  ${({ theme }) => theme.media.lg.min} {
    transform: translate(-11%, 63%);
  }

  ${({ theme }) => theme.media.xxl.min} {
    transform: translate(-6%, 63%);
  }
`

const CloudWrapper2 = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-57%, 52%);
  width: 1600px;
  z-index: 2;

  ${({ theme }) => theme.media.sm.min} {
    transform: translate(-43%, 57%);
  }

  ${({ theme }) => theme.media.lg.min} {
    transform: translate(-19%, 63%);
  }

  ${({ theme }) => theme.media.xxl.min} {
    transform: translate(-6%, 63%);
  }
`

const CloudWrapper3 = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-40%, 60%);
  width: max-content;
  width: 1600px;
  z-index: 2;

  ${({ theme }) => theme.media.xxl.min} {
    transform: translate(-47%, 60%);
  }
`

const CloudWrapper4 = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-64%, 55%);
  width: max-content;
  width: 1600px;
  z-index: 2;

  ${({ theme }) => theme.media.xxl.min} {
    transform: translate(-47%, 60%);
  }
`

const CloudWrapper5 = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-92%, 55%);
  width: max-content;
  width: 1600px;
  z-index: 2;

  ${({ theme }) => theme.media.xxl.min} {
    transform: translate(-98%, 55%);
  }
`

const CloudWrapper6 = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-98%, 55%);
  width: max-content;
  width: 1600px;
  z-index: 2;
`

const Header: React.FC<Props> = ({ title, subtitle, cloudImg }) => {
  const { lg, xxl } = useBreakpoint()

  useEffect(() => {
    const videoElement = document.getElementById('video')
    //@ts-ignore
    videoElement.play()
  }, [])

  return (
    <StyledHeader>
      <StyledContainer slim>
        <TextContent>
          <Heading
            as="h1"
            size={60}
            themecolor="white"
            weight={400}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <StyledHeading
            as="h3"
            size={22}
            themecolor="white"
            weight={300}
            margin="50px"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        </TextContent>
        <PatternWrapper>
          <Icon src={patternIcon} auto alt="pattern" />
          <IconButton
            onClick={() => scrollToSection('#ask-form', 'smooth', true)}
          >
            <Icon src={rectangleLight} size={100} alt="rectangle" />
            <Icon src={arrowDark} size={15} alt="arrow" />
          </IconButton>
        </PatternWrapper>
      </StyledContainer>
      <VideoWrapper>
        <video id="video" loop autoPlay playsInline muted>
          <source src={heroVideo} />
        </video>
      </VideoWrapper>
      <CloudWrapper>
        <LazyImage src={cloudImg} alt="cloud" />
      </CloudWrapper>
      <CloudWrapper2>
        <LazyImage src={cloudImg} alt="cloud" />
      </CloudWrapper2>
      {lg && (
        <CloudWrapper3>
          <LazyImage src={cloudImg} alt="cloud" />
        </CloudWrapper3>
      )}
      {lg && (
        <CloudWrapper4>
          <LazyImage src={cloudImg} alt="cloud" />
        </CloudWrapper4>
      )}
      {lg && (
        <CloudWrapper5>
          <LazyImage src={cloudImg} alt="cloud" />
        </CloudWrapper5>
      )}
      {xxl && (
        <CloudWrapper6>
          <LazyImage src={cloudImg} alt="cloud" />
        </CloudWrapper6>
      )}
    </StyledHeader>
  )
}

export default Header
