import * as React from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/layout'
import SEO from 'components/shared/SEO'
import Header from 'components/layout/Header'
import AskAboutFlat from 'components/layout/AskAboutFlat'
import About from 'components/layout/About'
import Flats from 'components/layout/Flats'
import Benefits from 'components/layout/Benefits'
import Location from 'components/layout/Location'
import Season from 'components/layout/Season'
import Details from 'components/layout/Details'
import Construction from 'components/layout/Construction'
import AboutCompany from 'components/layout/AboutCompany'
import Footer from 'components/layout/Footer'
import Cookies from 'components/shared/Cookies'

import { IndexPageQuery } from 'types/graphql/ssr'

const flatsTitle = 'Znajdź swoje mieszkanie'

const benefitsTitle = 'Udogodnienia <br/> osiedla'

const detailsSubtitle = 'Zalety programu wykończeń "pod klucz":'

const aboutCompanyTitle = 'grupa borstar'
const aboutCompanyDescription =
  'Firma Borstar to jedna z najbardziej dynamicznie rozwijających się firm budowlanych na Podkarpaciu. Jakość wykonywanych przez nas prac, dotrzymywanie terminów, rzetelne wykonywanie kosztorysów i profesjonalne podejście do Klienta od samego początku funkcjonowania buduje naszą renomę i zaufanie'

const IndexPage: React.FC<PageProps<IndexPageQuery>> = ({ data }) => {
  console.log(data)

  const HOMEPAGE = data.wpPage?.stronaGlowna

  const ABOUT_US_GALLERY = React.useMemo(
    () =>
      data.wpPage?.stronaGlowna?.aboutUsGallery.map((image) => ({
        src: image?.aboutUsGalleryImg?.localFile.childImageSharp
          .gatsbyImageData!,
        alt: image?.aboutUsGalleryImg?.altText,
      })),
    [],
  )

  const BENEFITS_GALLERY = React.useMemo(
    () =>
      HOMEPAGE?.amenityAssets.map((asset) => ({
        src: asset.amenityAssetsImg?.localFile?.childImageSharp
          ?.gatsbyImageData!,
        label: asset?.amenityAssetsType,
        icon: asset?.amenityAssetsIcon,
        alt: asset?.amenityAssetsIcon,
      })),
    [],
  )

  const DETAILS_GALLERY = React.useMemo(
    () =>
      HOMEPAGE?.finishingGallery.map((img) => ({
        src: img?.finishingGalleryImg?.localFile.childImageSharp
          ?.gatsbyImageData!,
        alt: img?.finishingGalleryImg.altText,
        info: img?.finishingGalleryMark,
      })),
    [],
  )

  const CONSTRUCTION_STAGES = React.useMemo(
    () =>
      HOMEPAGE?.logs.etapy.map((stage) => ({
        gallery: stage?.logsRepeaterGallery?.map((item) => ({
          src: item?.logsRepeaterGalleryItem?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: '',
          date: item?.logsRepeaterGalleryDate,
        })),
        stages: stage?.logsRepeaterStages.map((item) => ({
          stageActive: item?.logsRepeaterActive,
          stageTitle: item?.logsRepeaterTitle,
        })),
      })),
    [],
  )

  return (
    <Layout>
      <SEO title="Osiedle w Dolinie Sanu" />
      <Cookies />
      <Header
        title={HOMEPAGE?.heroText!}
        subtitle={HOMEPAGE?.heroTextSmaller!}
        cloudImg={data.cloudImg2?.childImageSharp?.gatsbyImageData}
      />
      <AskAboutFlat />
      <About
        title1={HOMEPAGE?.aboutUsTitle!}
        title2={HOMEPAGE?.aboutUsTitle2!}
        description1={HOMEPAGE?.aboutUsDescription!}
        description2={HOMEPAGE?.aboutUsDescription2!}
        gallery={ABOUT_US_GALLERY}
        img={HOMEPAGE?.aboutUsPzt?.localFile.childImageSharp?.gatsbyImageData!}
        cloudImg={data.cloudImg2Inverted?.childImageSharp?.gatsbyImageData}
      />
      <Flats title={flatsTitle} flats={data.wp.crm} />
      <Benefits
        title={benefitsTitle}
        gallery={BENEFITS_GALLERY}
        cloudImg={data.cloudImg2Inverted?.childImageSharp?.gatsbyImageData}
        cloudImg2={data.cloudImg2?.childImageSharp?.gatsbyImageData}
      />
      <Location
        mapImg={data.locationMap?.childImageSharp?.gatsbyImageData}
        mapImgMobile={data.locationMapMobile?.childImageSharp?.gatsbyImageData}
        pins={HOMEPAGE?.locationPins}
        mapPattern={data.mapPattern?.childImageSharp?.gatsbyImageData}
      />
      <Season
        title={HOMEPAGE?.locationTitle!}
        description={HOMEPAGE?.locationDescription!}
        winterImg={
          HOMEPAGE?.locationWinterImg?.localFile?.childImageSharp
            ?.gatsbyImageData!
        }
        winterImgMobile={
          data.winterImgMobile?.childImageSharp?.gatsbyImageData!
        }
        winterPlaces={HOMEPAGE?.locationWinterPlaces!}
        winterText={HOMEPAGE?.locationWinterText!}
        summerImg={
          HOMEPAGE?.locationSummerImg?.localFile?.childImageSharp
            ?.gatsbyImageData!
        }
        summerImgMobile={data.summerImgMobile?.childImageSharp?.gatsbyImageData}
        summerPlaces={HOMEPAGE?.locationSummerPlaces!}
        cloudImg={data.cloudImg2Inverted?.childImageSharp?.gatsbyImageData}
        summerText={HOMEPAGE?.locationSummerText!}
      />
      <Details
        title={HOMEPAGE?.finishingTitle!}
        description1={HOMEPAGE?.finishingDescription!}
        subtitle={detailsSubtitle}
        gallery={DETAILS_GALLERY}
        woodImg1={data.wood1?.childImageSharp?.gatsbyImageData}
        woodImg2={data.wood2?.childImageSharp?.gatsbyImageData}
      />
      <Construction
        title={HOMEPAGE?.logs?.logsHeading!}
        stages={CONSTRUCTION_STAGES}
      />
      <AboutCompany
        title={aboutCompanyTitle}
        description={aboutCompanyDescription}
        companyLogo={data.companyLogo.childImageSharp?.gatsbyImageData}
      />
      <Footer
        cloudImg={data.cloudImg2?.childImageSharp?.gatsbyImageData}
        logo={data.companyLogo2.childImageSharp?.gatsbyImageData}
      />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPage {
    wpPage {
      stronaGlowna {
        aboutUsDescription
        aboutUsDescription2
        aboutUsGallery {
          aboutUsGalleryImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
              }
            }
          }
        }
        aboutUsPzt {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 655, placeholder: BLURRED, formats: [WEBP])
            }
          }
        }
        aboutUsTitle
        aboutUsTitle2
        amenityAssets {
          amenityAssetsIcon
          amenityAssetsType
          amenityAssetsImg {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 500
                  placeholder: BLURRED
                  formats: [WEBP]
                )
              }
            }
          }
        }
        finishingDescription
        finishingGallery {
          finishingGalleryMark
          finishingGalleryImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1150
                  placeholder: BLURRED
                  formats: [WEBP]
                )
              }
            }
          }
        }
        finishingTitle
        heroText
        heroTextSmaller
        locationDescription
        locationPins {
          locationPinsLat
          locationPinsLng
          locationPinsType
        }
        locationTitle
        locationSummerText
        locationSummerPlaces {
          locationSummerPlacesSingle
        }
        locationSummerImg {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        locationWinterText
        locationWinterPlaces {
          locationWinterPlacesSingle
        }
        locationWinterImg {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        logs {
          logsStages
          logsHeading
          etapy {
            logsRepeaterGallery {
              logsRepeaterGalleryDate
              logsRepeaterGalleryItem {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 768
                      placeholder: BLURRED
                      formats: [WEBP]
                    )
                  }
                }
              }
            }
            logsRepeaterStages {
              logsRepeaterActive
              logsRepeaterTitle
            }
          }
        }
      }
    }
    wp {
      crm {
        flatBalcony
        flatBuilding
        flatFloor
        flatGardenSize
        flatId
        flatNumber
        flatPrice
        flatPriceVisibility
        flatRooms
        flatSize
        flatStatus
        flatType
      }
    }
    cloudImg1: file(name: { eq: "cloud" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
      }
    }
    cloudImg2: file(name: { eq: "cloud-2" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
      }
    }
    cloudImg2Inverted: file(name: { eq: "cloud-2-inverted" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
      }
    }
    mapPattern: file(name: { eq: "map-pattern" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
      }
    }
    winterImgMobile: file(name: { eq: "winter-mobile" }) {
      childImageSharp {
        gatsbyImageData(width: 992, placeholder: BLURRED, formats: [WEBP])
      }
    }
    summerImgMobile: file(name: { eq: "summer-mobile" }) {
      childImageSharp {
        gatsbyImageData(width: 992, placeholder: BLURRED, formats: [WEBP])
      }
    }
    wood1: file(name: { eq: "wood-1" }) {
      childImageSharp {
        gatsbyImageData(width: 650, placeholder: BLURRED, formats: [WEBP])
      }
    }
    wood2: file(name: { eq: "wood-2" }) {
      childImageSharp {
        gatsbyImageData(width: 450, placeholder: BLURRED, formats: [WEBP])
      }
    }
    companyLogo: file(name: { eq: "borstar-logo" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
      }
    }
    companyLogo2: file(name: { eq: "wild-estate-logo" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
      }
    }
  }
`
