import React, { useState, useMemo } from 'react'
import styled, { css } from 'styled-components'

import MapContainer from 'components/layout/Location/MapContainer'

import LazyImage from 'components/shared/lazyImage'
import Icon from 'components/shared/icon'
import { Text } from 'components/shared/typography'

import { ImageDataLike } from 'gatsby-plugin-image'

import mapIcon1 from 'assets/icons/map-icon-1.svg'
import mapIcon2 from 'assets/icons/map-icon-2.svg'
import mapIcon3 from 'assets/icons/map-icon-3.svg'
import mapIcon4 from 'assets/icons/map-icon-4.svg'
import mapIcon5 from 'assets/icons/map-icon-5.svg'

import useBreakpoint from 'hooks/useBreakpoint'

import LazyLoad from 'react-lazyload'

type PinType =
  | 'transport'
  | 'sport'
  | 'restaurant'
  | 'sklepy'
  | 'nature'
  | 'investment'
type HintLabelType =
  | 'Transport'
  | 'Sport'
  | 'Restauracje'
  | 'Sklepy'
  | 'Tereny zielone'

export type Pin = {
  locationPinsLat: string
  locationPinsLng: string
  locationPinsType: PinType
}

type Props = {
  pins: Pin[]
  mapPattern: ImageDataLike
}

const Section = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 60px 0;
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;

  ${({ theme }) => theme.media.xl.min} {
    padding-top: 200px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    padding-top: 290px;
  }
`

const MapWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 675px;

  ${({ theme }) => theme.media.lg.min} {
    height: 840px;
  }
`

const MapHintTop = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  min-width: 125px;
  padding: 0 25px;
  background-color: ${({ theme }) => theme.colors.white};
  border-left: 3px solid ${({ theme }) => theme.colors.dark200};
  border-right: 3px solid ${({ theme }) => theme.colors.dark200};
  border-bottom: 3px solid ${({ theme }) => theme.colors.dark200};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 1px 1px 3px 0px #1c5868;
  z-index: 1;
  visibility: hidden;

  ${({ visible }) =>
    visible &&
    css`
      visibility: visible;
    `}
`

const MapInfoBar = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 25px 10px 25px 15px;
  background-color: ${({ theme }) => theme.colors.secondary};

  ${({ theme }) => theme.media.xs.min} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  ${({ theme }) => theme.media.s.min} {
    padding: 25px 25px 25px 35px;
  }

  ${({ theme }) => theme.media.lg.min} {
    position: absolute;
    bottom: 100px;
    left: 140px;
    width: 70%;
    max-width: 1250px;
    padding: 20px 25px 20px 35px;

    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      transform: translateX(-80px);
      width: 0;
      height: 0;
      border-left: 80px solid transparent;
      border-right: 100px solid transparent;
      border-bottom: 100px solid ${({ theme }) => theme.colors.secondary};
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      transform: translateX(80px);
      width: 0;
      height: 0;
      border-left: 100px solid transparent;
      border-right: 80px solid transparent;
      border-top: 100px solid ${({ theme }) => theme.colors.secondary};
      z-index: 1;
    }
  }

  ${({ theme }) => theme.media.xxl.min} {
    left: 180px;
    width: 75%;
  }
`

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  ${({ theme }) => theme.media.xs.min} {
    margin-bottom: 0;
  }
`

const Icons = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
`

const IconWrapperButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.dark100};
  cursor: pointer;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.light};
  }

  :not(:last-child) {
    margin-right: 10px;
  }

  ${({ theme }) => theme.media.lg.min} {
    width: 60px;
    height: 60px;
  }
`

const PatternWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: -35px;
  width: 500px;
  transition: 0.25s;

  ${({ theme }) => theme.media.xxl.min} {
    width: 760px;
    top: 10px;
    right: -70px;
  }
`

const SectionScrollAnchor = styled.span`
  position: absolute;
  left: -20px;
  top: 0;

  ${({ theme }) => theme.media.xl.min} {
    top: 160px;
  }

  ${({ theme }) => theme.media.xl.min} {
    top: 260px;
  }
`

const Location: React.FC<Props> = ({ pins, mapPattern }) => {
  const [pinsGroupVisible, setPinsGroupVisible] = useState({
    transport: true,
    sport: true,
    sklepy: true,
    restaurant: true,
    nature: true,
    investment: true,
  })
  const [hoveredButtonLabel, setHoveredButtonLabel] = useState<
    HintLabelType | ''
  >('')

  const { lg, xl } = useBreakpoint()

  const togglePinsGroupVisibility = (pinType: PinType) =>
    setPinsGroupVisible({
      ...pinsGroupVisible,
      [pinType]: !pinsGroupVisible[pinType],
    })

  const mapPins = useMemo(
    () =>
      pins.map((pin) => ({
        ...pin,
        visible: pinsGroupVisible[pin.locationPinsType],
      })),
    [pins, pinsGroupVisible],
  )

  return (
    <Section>
      <SectionScrollAnchor id="lokalizacja" />
      <LazyLoad
        offset={800}
        height="106vh"
        style={{
          width: '100%',
        }}
        once
      >
        <MapWrapper>
          <MapHintTop visible={!!hoveredButtonLabel}>
            <Text
              size={20}
              themecolor="dark200"
              dangerouslySetInnerHTML={{ __html: hoveredButtonLabel }}
            />
          </MapHintTop>
          <MapContainer pins={mapPins} />
        </MapWrapper>
      </LazyLoad>
      <MapInfoBar>
        <TextContent>
          <Text size={lg ? 20 : 15} themecolor="light">
            Bieszczadzka
          </Text>
          <Text size={lg ? 20 : 15} themecolor="light">
            38-600 Lesko
          </Text>
        </TextContent>
        <Icons>
          <IconWrapperButton
            type="button"
            onClick={() => togglePinsGroupVisibility('sklepy')}
            onMouseOver={() => setHoveredButtonLabel('Sklepy')}
            onMouseOut={() => setHoveredButtonLabel('')}
          >
            <Icon src={mapIcon1} size={lg ? 35 : 22} alt="icon" />
          </IconWrapperButton>
          <IconWrapperButton
            type="button"
            onClick={() => togglePinsGroupVisibility('transport')}
            onMouseOver={() => setHoveredButtonLabel('Transport')}
            onMouseOut={() => setHoveredButtonLabel('')}
          >
            <Icon src={mapIcon2} size={lg ? 35 : 22} alt="icon" />
          </IconWrapperButton>
          <IconWrapperButton
            type="button"
            onClick={() => togglePinsGroupVisibility('sport')}
            onMouseOver={() => setHoveredButtonLabel('Sport')}
            onMouseOut={() => setHoveredButtonLabel('')}
          >
            <Icon src={mapIcon3} size={lg ? 35 : 22} alt="icon" />
          </IconWrapperButton>
          <IconWrapperButton
            type="button"
            onClick={() => togglePinsGroupVisibility('restaurant')}
            onMouseOver={() => setHoveredButtonLabel('Restauracje')}
            onMouseOut={() => setHoveredButtonLabel('')}
          >
            <Icon src={mapIcon4} size={lg ? 35 : 22} alt="icon" />
          </IconWrapperButton>
          <IconWrapperButton
            type="button"
            onClick={() => togglePinsGroupVisibility('nature')}
            onMouseOver={() => setHoveredButtonLabel('Tereny zielone')}
            onMouseOut={() => setHoveredButtonLabel('')}
          >
            <Icon src={mapIcon5} size={lg ? 50 : 36} alt="icon" />
          </IconWrapperButton>
        </Icons>
      </MapInfoBar>

      {xl && (
        <PatternWrapper>
          <LazyImage src={mapPattern} alt="map-pattern" />
        </PatternWrapper>
      )}
    </Section>
  )
}

export default Location
