export default {
  pl: {
    // Globals
    allFlats: 'Liczba mieszkań',
    available: 'Dostępne',
    loading: 'Ładowanie',
    loading_building: 'Ładowanie modułu 3D',
    loading_tour: 'Ładowanie wirtualnego spaceru',
    back: 'Powrót',
    back2: 'Wstecz',

    // Topbar
    choose_city: 'Wybierz miasto',
    choose_investment: 'Wybierz inwestycje',
    choose_building: 'Wybierz budynek',
    choose_floor: 'Wybierz piętro',
    choose_flat: 'Wybierz mieszkanie',
    wishlist: 'Schowek',

    // Breadcrumbs
    building: 'Budynek',
    stage: 'Etap',
    floor: 'Piętro',
    groundFloorShort: 'P',

    // Legend
    room1: '1-pokojowe',
    room2: '2-pokojowe',
    room3: '3-pokojowe',
    room4: '4-pokojowe',
    room5: '5-pokojowe',

    // Flat
    from: 'od',
    to: 'do',
    apartment: 'Lokal',
    flat: 'Mieszkanie',
    local: 'Lokal usługowy',
    investment: 'Inwestycja',
    interior: 'Wnętrza',
    surroundings: 'Okolica',
    number: 'Numer',
    area: 'Powierzchnia',
    rooms: 'Pokoje',
    balcony: 'Balkon',
    status: 'Status',
    sale: 'Promocja',
    status_available: 'Dostępne',
    status_unavailable: 'Sprzedane',
    status_reserved: 'Rezerwacja',
    status_sold: 'Sprzedane',
    status_planned: 'Planowany',
    hideFilters: 'Zwiń filtry',

    homepage: 'Strona główna',
    flats: 'Mieszkania',
    warszawa: 'Warszawa',
    krakow: 'Kraków',
    warszawa_locative: 'Mieszkania w Warszawie',
    warszawa_locative2: 'Mieszkania<br/>w Warszawie',
    krakow_locative: 'Mieszkania w Krakowie',
    krakow_locative2: 'Mieszkania<br/>w Krakowie',
    find_apartments_in_warszawa: 'Znajdź mieszkanie w Warszawie',
    find_apartments_in_krakow: 'Znajdź mieszkanie w Krakowie',
    browser3D: 'Wyszukiwarka 3D',
    askForOffer: 'Zapytaj o ofertę',
    listOfApartments: 'Lista mieszkań',
    findApartment: 'Znajdź mieszkanie',
    surface: 'Powierzchnia',
    city: 'Miasto',
    district: 'Dzielnica',
    numberOfRooms: 'Liczba pokoi',
    price: 'Cena',
    chooseFromList: 'Wybierz z listy...',
    localization: 'Lokalizacja',
    availableFlats: 'Dostępne mieszkania',
    investments: 'Inwestycje',
    filterFlats: 'Filtruj mieszkania',
    removeFilters: 'Usuń filtry',
    removeAll: 'Usuń wszystkie',
    flatsInWishlist: 'Mieszkań w schowku',
    noWishlistFlats: 'Brak mieszkań w schowku',
    day: 'Dzień',
    night: 'Noc',
    investmentMap: 'Mapa inwestycji',
    investmentGallery: 'Galeria inwestycji',
    gallery: 'Galeria',
    loadingMap: `Ładowanie mapy`,
    loadingFlats: `Ładowanie mieszkań`,

    // Form
    acceptAll: 'Zaznacz wszystkie zgody',
    name: 'Imię',
    surname: 'Nazwisko',
    email: 'Adres e-mail',
    phone: 'Numer telefonu',
    message: 'Wiadomość',
    nameErrorReq: 'Proszę o podanie imienia',
    surnameErrorReq: 'Proszę o podanie nazwiska',
    emailErrorReq: 'Proszę podać adres e-mail',
    emailErrorInvalid: 'Adres email jest nieprawidłowy',
    phoneErrorReq: 'Proszę podać numer telefonu',
    phoneErrorInvalid: 'Numer telefonu jest nieprawidłowy',
    termsErrorReq: 'Proszę o zaznaczenie zgód',
    messageErrorReq: 'Proszę podać treść wiadomości',
    roomsMessage: 'Ile pokoi Cię interesuje?',
    sendMessage: 'Wyślij wiadomość',
    errorMessage: 'Coś poszło nie tak',
    successMessage: 'Wiadomość została wysłana!',
    more: 'Więcej',
    less: 'Mniej',

    // Navigation & Footer
    apartments: 'Mieszkania',
    aboutMatexi: 'O Matexi',
    welcomeMatexi: 'Witamy w okolicy',
    logBuilding: 'Dziennik budowy',
    contact: 'Kontakt',
    matexiGroup: 'Grupa Matexi',
    matexiPoland: 'Matexi Polska',
    saleOffices: 'Biura',
    forTheMedia: 'Dla mediów',
    completedProjects: 'Inwestycje zrealizowane',
    plannedProjects: 'Inwestycje planowane',
    commercialUnits: 'Lokale usługowe',
    allRightsReserved: '© 2021 Matexi Polska Wszelkie prawa zastrzeżone.',
    cookies: 'Cookies',
    privacyPolicy: 'Polityka prywatności',
    madeBy: 'Wykonane przez',
    weAreMemberOf: 'Jesteśmy członkiem',
    writeToUs: 'Napisz do nas',

    allLabel: 'Wszystkie',
    findOutMore: 'Dowiedz się więcej',
    chooseShort: 'Wybierz...',
    noPosts: 'Brak wpisów',
    noInvestments: 'Brak inwestycji',
    findYourFlat: 'Znajdź swoje mieszkanie',
    showMyLayouts: 'Pokaż dopasowane dla mnie układy',
    showAllLayouts: 'Pokaż wszystkie układy',
    cardView: 'Widok kart',
    cards: 'Karty',
    listView: 'Widok listy',
    list: 'Lista',
    noMatchingFlats: 'Żadne mieszkanie nie spełnia kryteriów wyszukiwania',
    askAboutFlats: 'Zapytaj o mieszkania',
    seeMore: 'Zobacz więcej',
    seeAllInvestments: 'Zobacz wszystkie inwestycje',
    investmentsMap3D: 'Mapa inwestycji 3D',
    areYouInterested: 'Jesteś zainteresowany mieszkaniem na tym osiedlu?',
    areYouInterestedInvestment:
      'Jesteś zainteresowany mieszkaniem w tej inwestycji?',
    areYouInterestedExt:
      'Jesteś zainteresowany tą inwestycją?<br/>Skontaktuj się z nami',
    ask: 'Zapytaj',
    call: 'Zadzwoń',
    pageNotFound: 'Nie znaleziono strony',

    // Flat
    flatView: 'Widok mieszkania',
    floorView: 'Widok piętra',
    tour3d: 'Wirtualny spacer 3D',
    addToWishlist: 'Dodaj do schowka',
    removeFromWishlist: 'Usuń ze schowka',
    downloadPDF: 'Pobierz kartę',
    book: 'Zarezerwuj',
    askAboutFlat: 'Zapytaj o mieszkanie',
    askAboutInvestment: 'Zapytaj o inwestycję',
    bookFlat: 'Zarezerwuj mieszkanie',
    checkOut: 'Sprawdź',
    goBackToFlat: 'Wróć do karty mieszkania',
    noPreview: 'Brak podglądu',
    nr: 'Nr',
    theSameLayout: 'Taki sam <br />układ mieszkania:',
    chooseFromSimilars: 'Wybierz spośród podobnych mieszkań',
    remove: 'Usuń',
    add: 'Dodaj',
    fromWishlist: 'ze',
    toWishlist: 'do',
    wishlistLocative: 'schowka',
  },
}
