import React, { useContext } from 'react'
import styled from 'styled-components'
import Cards from 'browser/components/Cards'
import Table from 'browser/components/Table'
import {
  Section,
  Content,
  FlatsContent,
  EmptyInfo,
  FiltersWrapper,
  FilterColumn,
  CheckboxWrapper,
  Checkmark,
  FilterInfo,
  InfoWrapper,
  ViewButtonsWrapper,
  FilterItem,
} from 'browser/components/FlatsBrowser/components'
import Pagination from 'browser/components/Pagination'
import { ViewButton } from 'browser/components/Button'
import RangeInput from 'browser/components/Filters/RangeInput'
import TourModal from 'browser/components/Flat/TourModal'
import { LangContext } from 'browser/contexts/LangContext'
import Select from 'browser/components/Filters/Select'
import FlatForm from 'browser/components/Form/Form'
import useFlats from 'browser/hooks/useFlats'
import Icon from 'browser/components/Icon'

import rectangleLightIcon from 'assets/icons/rectangle-light.svg'
import tilesDarkIcon from 'browser/assets/icons/tiles-dark.svg'
import tilesGrayIcon from 'browser/assets/icons/tiles-gray.svg'
import listDarkIcon from 'browser/assets/icons/list-dark.svg'
import listGrayIcon from 'browser/assets/icons/list-gray.svg'

const StyledContent = styled(Content)`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: unset;
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.dark200};
  /* margin-bottom: 20px; */
  padding: 30px 20px 15px;

  ${({ theme }) => theme.mq.lg} {
    height: 220px;
  }
`

const FitersButton = styled.button`
  position: relative;
  align-self: center;
  justify-self: center;
  width: 100%;
  max-width: 240px;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.dark300};
  border: none;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.light};
  cursor: pointer;
  transition: 0.3s;

  &:after {
    content: '';
    position: absolute;
    display: block;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 40%;
    height: 3px;
    background-color: ${({ theme }) => theme.colors.light};
  }

  ${({ theme }) => theme.mq.lg} {
  }
`

const ButtonIcons = styled.div`
  position: absolute;
  top: 50%;
  right: 15%;
  transform: translateY(-50%);

  & > :first-child {
    margin-right: 3px;
  }
`

const Flats = () => {
  const { translate } = useContext(LangContext)
  const {
    formState,
    tour3DState,
    filters,
    allFlats,
    saleOnly,
    filteredFlats,
    selectedFloorsText,
    firstFloor,
    lastFloor,
    viewType,
    pages,
    activePage,
    sortProperty,
    uniqueLayoutsNum,
    handleSetViewType,
    setSaleOnly,
    showTour3D,
    setTour3DState,
    handleSetSortProperty,
    updateFlats,
    onButtonClick,
    setActivePage,
    closeForm,
    toggleForm,
    handleStatusesMultiSelect,
    handleStagesMultiSelect,
    handleRoomsMultiSelect,
    handleFloorMultiSelect,
    handleAreaRange,
    handlePriceRange,
    applyFilters,
  } = useFlats()

  console.log(filters.stages)

  return (
    <>
      {tour3DState.src && (
        <TourModal
          src={tour3DState.src}
          isVisible
          flat={tour3DState.flat}
          close={() => setTour3DState({ src: null, flat: null })}
        />
      )}
      <FlatForm
        isVisible={formState.visible}
        flat={formState.name}
        investment={formState.investment}
        heading={
          formState.bookFlat ? translate('bookFlat') : translate('askAboutFlat')
        }
        closeForm={closeForm}
        bookFlat={formState.bookFlat}
      />
      <Section>
        <StyledContent>
          <FilterColumn>
            <FiltersWrapper>
              <FilterItem>
                <Select
                  allOptions={filters.stages.all.sort((a, b) => a - b)}
                  selectedOptions={filters.stages.selected.sort(
                    (a, b) => a - b,
                  )}
                  handleSelect={handleStagesMultiSelect}
                  placeholder={translate('stage')}
                  multiSelect
                  first
                />
              </FilterItem>
              <FilterItem>
                <Select
                  allOptions={filters.rooms.all.sort((a, b) => a - b)}
                  selectedOptions={filters.rooms.selected.sort((a, b) => a - b)}
                  handleSelect={handleRoomsMultiSelect}
                  placeholder={translate('numberOfRooms')}
                  multiSelect
                />
              </FilterItem>
              <FilterItem>
                <RangeInput
                  value={filters.area.values}
                  min={filters.area.extremes[0]}
                  max={filters.area.extremes[1]}
                  handleChange={handleAreaRange}
                  unit="m2"
                />
              </FilterItem>
              <FilterItem>
                <Select
                  allOptions={filters.floors.all.sort((a, b) => a - b)}
                  selectedOptions={filters.floors.selected.sort(
                    (a, b) => a - b,
                  )}
                  handleSelect={handleFloorMultiSelect}
                  placeholder={translate('floor')}
                  multiSelect
                />
              </FilterItem>
              <FilterItem>
                <Select
                  allOptions={filters.statuses.all.sort((a, b) => a - b)}
                  selectedOptions={filters.statuses.selected.sort(
                    (a, b) => a - b,
                  )}
                  handleSelect={handleStatusesMultiSelect}
                  placeholder={translate('status')}
                  multiSelect
                />
              </FilterItem>
              {/* <FitersButton onClick={applyFilters}>
                Wyszukaj
                <ButtonIcons>
                  <Icon src={rectangleLightIcon} size={8} alt="rectangle" />
                  <Icon src={rectangleLightIcon} size={8} alt="rectangle" />
                </ButtonIcons>
              </FitersButton> */}
            </FiltersWrapper>
          </FilterColumn>
        </StyledContent>
        <InfoWrapper>
          <ViewButtonsWrapper>
            <ViewButton
              onClick={() => handleSetViewType('cards')}
              active={viewType === 'cards'}
            >
              {translate('cards')}
              <Icon
                src={viewType === 'cards' ? tilesDarkIcon : tilesGrayIcon}
                size={26}
                alt="tiles"
              />
            </ViewButton>
            <ViewButton
              onClick={() => handleSetViewType('list')}
              active={viewType === 'list'}
            >
              {translate('list')}
              <Icon
                src={viewType === 'list' ? listDarkIcon : listGrayIcon}
                size={26}
                alt="list"
              />
            </ViewButton>
          </ViewButtonsWrapper>
        </InfoWrapper>
        <FlatsContent id="flats2d-content" list={viewType === 'list'}>
          {viewType === 'cards' && (
            <Cards
              flats={pages[activePage]}
              allFlats={filteredFlats.filter((flat) => flat.status !== 0)}
              showTour3D={showTour3D}
              toggleForm={toggleForm}
            />
          )}
          {viewType === 'list' && (
            <Table
              sortProperty={sortProperty}
              setSortProperty={handleSetSortProperty}
              columns={[
                { label: translate('flat'), property: 'name' },
                { label: translate('stage'), property: 'stage' },
                { label: translate('area'), property: 'area' },
                { label: translate('floor'), property: 'floor' },
                { label: translate('rooms'), property: 'rooms' },
                { label: translate('balcony'), property: 'balcony' },
                // { label: translate('price'), property: 'price' },
                { label: translate('status'), property: 'status' },
              ]}
              allFlats={filteredFlats}
              flats={pages[activePage]}
              setSortedFlats={updateFlats}
              onButtonClick={onButtonClick}
            />
          )}
          {filteredFlats.length === 0 && (
            <EmptyInfo>
              <p>{translate('noMatchingFlats')}</p>
            </EmptyInfo>
          )}
        </FlatsContent>
        <Content>
          {pages.length > 1 && (
            <Pagination
              activePage={activePage}
              setActivePage={setActivePage}
              totalPages={pages.length}
              selector="#flats2d-content"
            />
          )}
        </Content>
      </Section>
    </>
  )
}

export default Flats
