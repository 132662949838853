import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'

import About1 from 'components/layout/About/About1'
import About2 from 'components/layout/About/About2'

import type { Gallery } from 'components/layout/About/About1'

import { ImageDataLike } from 'gatsby-plugin-image'

import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  title1: string
  title2: string
  description1: string
  description2: string
  gallery: Gallery
  img: ImageDataLike
  cloudImg: ImageDataLike
}

const Section = styled.section`
  position: relative;
  width: 100%;
  z-index: 1;
  padding: 40px 0;
  overflow: hidden;
`

const StyledContainer = styled(Container)`
  ${({ theme }) => theme.media.lg.min} {
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;
  }

  ${({ theme }) => theme.media.xxl.min} {
    padding: 0;
  }
`

const Background = styled.div`
  position: absolute;
  top: 0;
  bottom: 17.5%;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.primary};

  ${({ theme }) => theme.media.lg.min} {
    bottom: 23.5%;
  }

  @media (min-width: 1241px) {
    bottom: 19.5%;
  }

  ${({ theme }) => theme.media.xxl.min} {
    bottom: 13.5%;
  }
`

const About: React.FC<Props> = ({
  title1,
  title2,
  description1,
  description2,
  gallery,
  img,
  cloudImg,
}) => {
  const { xxl } = useBreakpoint()

  return (
    <Section title={title1.replace(/<br\/> /g, '')} id="o-osiedlu">
      <Background />
      <StyledContainer narrow={xxl ? true : false}>
        <About1
          titleMobile={title1}
          titleDesktop={title2}
          descriptionMobile={description1}
          descriptionDesktop={description2}
          gallery={gallery}
        />
        <About2
          titleMobile={title2}
          titleDesktop={title1}
          descriptionMobile={description2}
          descriptionDesktop={description1}
          img={img}
          cloudImg={cloudImg}
        />
      </StyledContainer>
    </Section>
  )
}

export default About
