import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactSlider from 'react-slider'
import './input-range.css'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-bottom: 50px;
`

const InputWrapper = styled.div`
  width: 100%;
  padding-top: 46px;
`

const ValuesWrapper = styled.div`
  position: absolute;
  bottom: -40px;
  right: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Value = styled.span`
  font-size: 1.1rem;
  font-weight: ${({ theme }) => theme.medium};
  color: ${({ theme }) => theme.colors.light};
  span {
    font-size: 1.1rem;
    color: ${({ theme }) => theme.colors.light};
    margin-left: 2px;
  }
`

const Label = styled.span`
  position: absolute;
  top: 10px;
  left: 0;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.gray};
  opacity: 0.75;
`

const RangeInput = ({ min, max, value, minDistance, unit, handleChange }) => {
  return (
    <Wrapper>
      <Label>Powierzchnia</Label>
      <ValuesWrapper>
        <Value>
          {value[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          {unit === 'm2' ? (
            <span>
              m<sup>2</sup>
            </span>
          ) : (
            <span>{unit}</span>
          )}
        </Value>
        <Value>
          {value[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          {unit === 'm2' ? (
            <span>
              m<sup>2</sup>
            </span>
          ) : (
            <span>{unit}</span>
          )}
        </Value>
      </ValuesWrapper>
      <InputWrapper>
        <ReactSlider
          className="horizontal-slider"
          thumbClassName="slider-thumb"
          trackClassName="slider-track"
          min={min}
          max={max}
          value={value}
          minDistance={minDistance}
          onAfterChange={handleChange}
        />
      </InputWrapper>
    </Wrapper>
  )
}

RangeInput.propTypes = {
  unit: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
  minDistance: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
}

RangeInput.defaultProps = {
  minDistance: 10,
}

export default RangeInput
