import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import FlatCard from 'browser/components/FlatsBrowser/FlatCard'

const Wrapper = styled.div`
  display: grid;
  column-gap: 15px;
  row-gap: 80px;
  width: 100%;
  grid-template-columns: repeat(1, 1fr);
  @media (min-width: 670px) {
    grid-template-columns: repeat(2, 1fr);
  }
  ${({ theme }) => theme.mq.lg} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const Cards = ({ flats, allFlats, showTour3D, toggleForm }) => {
  return (
    <Wrapper>
      {flats.map((flatData, index) => (
        <FlatCard
          key={flatData.name + index}
          flatData={flatData}
          showTour3D={showTour3D}
          toggleForm={toggleForm}
          similarFlats={
            flatData.similarFlats &&
            allFlats.filter(({ name }) =>
              flatData.similarFlats.map(({ flat }) => flat).includes(name),
            )
          }
        />
      ))}
    </Wrapper>
  )
}

Cards.propTypes = {
  flats: PropTypes.arrayOf(PropTypes.object),
  allFlats: PropTypes.arrayOf(PropTypes.object),
  showTour3D: PropTypes.func,
  toggleForm: PropTypes.func,
}

Cards.defaultProps = {
  flats: [],
  allFlats: [],
  showTour3D: () => null,
  toggleForm: () => null,
}

export default Cards
