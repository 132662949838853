/* eslint-disable */
import React, { useState } from 'react'
import { Formik, Field, Form, FormikHelpers } from 'formik'
import styled, { css } from 'styled-components'

import Input from 'components/layout/AskAboutFlat/Input'
import Terms from 'components/layout/AskAboutFlat/Terms'

import Container from 'components/shared/container'
import { Heading } from 'components/shared/typography'
import Button from 'components/shared/button'

import {
  ASK_ABOUT_FLAT_FORM_SCHEMA,
  ASK_ABOUT_FLAT_INIT_VALUES,
  AskAboutFlatValues,
} from 'constants/form'

import axios from 'axios'

type Props = {
  title: string
}

const StyledContainer = styled(Container)`
  ${({ theme }) => theme.media.xxl.min} {
    padding: 0;
  }
`

const StyledForm = styled.div`
  width: 100%;

  & > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 2rem;

    ${({ theme }) => theme.media.lg.min} {
      margin-top: 4.5rem;
    }
  }
`

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  // Message input
  & > :last-child {
    margin-top: 2rem;
  }

  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

const TermsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.lg.min} {
    align-self: flex-start;
    width: 35%;
  }
`

const StyledButton = styled(Button)`
  position: relative;
  width: 220px;
  min-width: 220px;
  height: 55px;
  z-index: 5;
  margin: 2rem 0 0;
  ${({ theme }) => theme.media.lg.min} {
    align-self: center;
    margin: 30px 0 0;
  }
`

const TermsErrorMessage = styled.p`
  width: 100%;
  margin-bottom: 2rem;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.danger};
  text-align: left;
`

const FormMessage = styled.p<{ error?: boolean }>`
  width: 100%;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: #25c925;
  text-align: left;

  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) => theme.colors.danger};
    `};
`

const ModalForm: React.FC<Props> = ({ title }) => {
  const [formInfo, setFormInfo] = useState({
    text: '',
    error: false,
  })

  return (
    <StyledContainer narrow>
      <Heading
        size={36}
        weight={500}
        themecolor="dark"
        align="center"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <Formik
        initialValues={ASK_ABOUT_FLAT_INIT_VALUES}
        validationSchema={ASK_ABOUT_FLAT_FORM_SCHEMA}
        onSubmit={(
          values: AskAboutFlatValues,
          { setSubmitting, resetForm }: FormikHelpers<AskAboutFlatValues>,
        ) => {
          if (formInfo.text && !formInfo.error) return
          ;(async () => {
            try {
              setSubmitting(true)
              const formData = new FormData()

              formData.append('name', values.name)
              formData.append('phone', values.phone || '')
              formData.append('email', values.email)
              formData.append('message', values.message)
              formData.append('agree1', values.agree1.toString())
              formData.append('agree2', values.agree2.toString())

              const data = await axios.post(
                'https://serwer2283083.home.pl/CMS/main-contact-form/form.php',
                formData,
              )

              setSubmitting(false)

              setFormInfo({
                text: 'Dziękujemy za wysłanie formularza!',
                error: false,
              })

              setTimeout(() => {
                setFormInfo({ text: '', error: false })
                resetForm()
              }, 7000)
            } catch (err) {
              setSubmitting(false)
              setFormInfo({ text: 'Coś poszło nie tak!', error: true })
              setTimeout(() => {
                setFormInfo({ text: '', error: false })
              }, 7000)
            }
          })()
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <StyledForm>
            <Form>
              <InputsWrapper>
                <Field name="name" placeholder="Imię" component={Input} />
                <Field name="email" placeholder="E-mail" component={Input} />
                <Field
                  name="phone"
                  placeholder="Numer telefonu"
                  component={Input}
                />
                <Field
                  name="message"
                  placeholder="Wiadomość"
                  textarea
                  component={Input}
                />
              </InputsWrapper>
              {((errors.agree1 && touched.agree1) ||
                (errors.agree2 && touched.agree2)) && (
                <TermsErrorMessage>Proszę zaznaczyć zgody</TermsErrorMessage>
              )}
              {formInfo.text && (
                <FormMessage error={formInfo.error}>
                  {formInfo.text}
                </FormMessage>
              )}
              <TermsWrapper>
                <Terms />
              </TermsWrapper>
              <StyledButton loading={isSubmitting} type="submit">
                {isSubmitting ? '' : 'Wyślij'}
              </StyledButton>
            </Form>
          </StyledForm>
        )}
      </Formik>
    </StyledContainer>
  )
}

export default ModalForm
