import React, { useState } from 'react'
import styled from 'styled-components'

import SwiperCore from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

import Container from 'components/shared/container'
import LazyImage from 'components/shared/lazyImage'
import Icon from 'components/shared/icon'
import { Heading, Text } from 'components/shared/typography'

import { ImageDataLike } from 'gatsby-plugin-image'

import background from 'assets/images/background-diamond-pattern.png'
import rectangleBtnLeft from 'assets/icons/rectangle-button-left.svg'
import rectangleBtnRight from 'assets/icons/rectangle-button-right-2.svg'
// import plusIcon from 'assets/icons/plus-light.svg'

import useBreakpoint from 'hooks/useBreakpoint'

import LazyLoad from 'react-lazyload'

type Slide = {
  src: ImageDataLike
  alt: string
  info: string
}

type Props = {
  title: string
  description1: string
  subtitle: string
  gallery: Slide[]
  woodImg1: ImageDataLike
  woodImg2: ImageDataLike
}

const Section = styled.section`
  padding: 50px 0;
  overflow: hidden;
`

const StyledContainer = styled(Container)`
  position: relative;
  margin-bottom: 50px;

  ${({ theme }) => theme.media.lg.min} {
    & > ${Text} {
      max-width: 500px;
    }
  }

  ${({ theme }) => theme.media.xl.min} {
    & > ${Text} {
      max-width: 640px;
    }
  }
`

const StyledContainer2 = styled(Container)`
  padding: 0;

  ${({ theme }) => theme.media.lg.min} {
    display: flex;
    align-items: stretch;
    justify-content: center;
  }
`

const SwiperWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  align-self: center;
  max-width: 100%;
  margin-top: 30px;

  ${({ theme }) => theme.media.lg.min} {
    width: 60%;
    margin: 0;
  }

  ${({ theme }) => theme.media.xxl.min} {
    width: 70%;
  }
`

const SwiperHeader = styled.div`
  position: relative;
  padding-left: ${({ theme }) => theme.container.paddingMD};
  padding-right: ${({ theme }) => theme.container.paddingMD};
  margin-bottom: 50px;

  ${({ theme }) => theme.media.lg.min} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;

    & > ${Heading} {
      margin-top: min(3.25vw, 75px);
    }

    ${({ theme }) => theme.media.xxl.min} {
      width: 30%;
      margin-bottom: 0;
    }
  }
`

const List = styled.ul`
  margin-top: 30px;

  ${({ theme }) => theme.media.lg.min} {
    margin-bottom: 30px;
  }
`

const ListItem = styled.li`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  &:before {
    display: block;
    content: '';
    min-width: 8px;
    min-height: 8px;
    margin-right: 15px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.dark100};
  }
`

const SlideWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 80px;
  min-height: 300px;
  // Img wrapper
  & > div:first-child {
    height: 100%;
    min-height: 300px;
    max-height: 730px;
  }

  ${({ theme }) => theme.media.lg.min} {
    margin-right: 20px;
    padding: 0;
  }
`

// const SlideAdditionalInfo = styled.div<{ visible: boolean }>`
//   position: absolute;
//   top: calc(50% - 40px);
//   left: 50%;
//   transform: translate(-50%, -50%) scale(0);
//   padding: 15px;
//   background-color: ${({ theme }) => theme.colors.white};
//   border: 2px solid ${({ theme }) => theme.colors.dark200};
//   border-radius: 10px;
//   width: 100%;
//   max-width: 240px;
//   z-index: 2;
//   transition: 0.25s;
//   visibility: hidden;

//   ${({ visible }) =>
//     visible &&
//     css`
//       visibility: visible;
//       transform: translate(-50%, -50%) scale(1);
//     `}

//   ${({ theme }) => theme.media.lg.min} {
//     z-index: 0;
//     padding: 15px 15px 60px 15px;
//     transform: translate(-100%, -75%) scale(0);
//     transform-origin: 100% 100%;
//     ${({ visible }) =>
//       visible &&
//       css`
//         visibility: visible;
//         transform: translate(-100%, -75%) scale(1);
//       `}
//   }
// `

// const CircleButton = styled.button`
//   position: absolute;
//   top: calc(50% - 40px);
//   left: 50%;
//   transform: translate3d(-50%, -50%, 100px);
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 80px;
//   height: 80px;
//   border-radius: 50%;
//   background-color: ${({ theme }) => theme.colors.secondary};
//   z-index: 1;
//   transition: 0.3s;

//   &:hover {
//     transform: translate(-50%, -50%) rotate(90deg);
//   }

//   ${({ theme }) => theme.media.lg.min} {
//     top: 50%;
//   }
// `

const SlideIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translate3d(-50%, 0, 100px);
  width: fit-content;
  z-index: 2;
`
const ControlButtons = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  align-self: flex-end;
  justify-self: flex-end;
`

const ControlButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const Wood1Wrapper = styled.div`
  position: absolute;
  top: 50%;
  right: -300px;
  transform: translateY(-50%);
  background-image: url('${background}');
  padding-left: 300px;

  padding-top: 130px;
  padding-bottom: 130px;
  ${({ theme }) => theme.media.xxl.min} {
    right: -200px;
  }
`
const Wood2Wrapper = styled.div`
  position: absolute;
  top: 93%;
  left: -200px;
  transform: translateY(-50%);
`

const Details: React.FC<Props> = ({
  title,
  description1,
  subtitle,
  gallery,
  woodImg1,
  woodImg2,
}) => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null)
  // const [isSlideBtnHovered, setIsSlideBtnHovered] = useState(false)

  const { lg, xxl } = useBreakpoint()

  const goPrev = () => {
    swiper?.slidePrev()
  }

  const goNext = () => {
    swiper?.slideNext()
  }

  return (
    <Section title={title.replace(/<br\/> /g, '')} id="studio-projektowe">
      <StyledContainer>
        <Heading
          as="h3"
          size={42}
          weight={500}
          margin="40px"
          themecolor="dark100"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Text
          line={2.2}
          themecolor="dark100"
          dangerouslySetInnerHTML={{ __html: description1 }}
        />
        {lg && (
          <Wood1Wrapper>
            <LazyImage src={woodImg1} alt="wood-white" />
          </Wood1Wrapper>
        )}
      </StyledContainer>
      <StyledContainer2>
        <SwiperHeader>
          <Heading
            as="h3"
            size={36}
            weight={500}
            margin={lg ? '10px' : ' 30px'}
            themecolor="dark100"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
          <List>
            <ListItem>
              <Text
                size={18}
                themecolor="dark100"
                dangerouslySetInnerHTML={{
                  __html:
                    'Pełne wsparcie i nadzór architekta na etapie projektowania i realizacji prac.',
                }}
              />
            </ListItem>
            <ListItem>
              <Text
                size={18}
                themecolor="dark100"
                dangerouslySetInnerHTML={{
                  __html: 'Projekt dopasowany do Twoich potrzeb i oczekiwań.',
                }}
              />
            </ListItem>
            <ListItem>
              <Text
                size={18}
                themecolor="dark100"
                dangerouslySetInnerHTML={{
                  __html:
                    'Sprawdzeni wykonawcy - realizację powierzasz specjalistom.',
                }}
              />
            </ListItem>
            <ListItem>
              <Text
                size={18}
                themecolor="dark100"
                dangerouslySetInnerHTML={{
                  __html:
                    'Wiedza i doświadczenie: wykorzystanie sprawdzonych rozwiązań rynkowych.',
                }}
              />
            </ListItem>
            <ListItem>
              <Text
                size={18}
                themecolor="dark100"
                dangerouslySetInnerHTML={{
                  __html:
                    'Przewidywalność wydatków i znajomość kosztów już od etapu projektu wnętrza.',
                }}
              />
            </ListItem>
          </List>
          {lg && (
            <ControlButtons>
              <ControlButton onClick={goPrev}>
                <Icon src={rectangleBtnLeft} width={80} alt="rectangle-btn" />
              </ControlButton>
              <ControlButton onClick={goNext}>
                <Icon src={rectangleBtnRight} width={80} alt="rectangle-btn" />
              </ControlButton>
            </ControlButtons>
          )}
          {xxl && (
            <Wood2Wrapper>
              <LazyImage src={woodImg2} alt="wood-dark" />
            </Wood2Wrapper>
          )}
        </SwiperHeader>
        <SwiperWrapper>
          <LazyLoad
            offset={500}
            height="100%"
            style={{
              width: '100%',
            }}
            once
          >
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              onSwiper={(initSwiper) => setSwiper(initSwiper)}
              loop
              speed={1000}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
            >
              {gallery.map(({ src, alt }, index) => (
                <SwiperSlide key={index}>
                  <SlideWrapper>
                    <LazyImage src={src} alt={alt} />
                    {/* <CircleButton
                      onMouseOver={() => setIsSlideBtnHovered(true)}
                      onMouseOut={() => setIsSlideBtnHovered(false)}
                    >
                      <Icon src={plusIcon} size={15} alt="plus" />
                    </CircleButton>
                    <SlideAdditionalInfo
                      visible={isSlideBtnHovered}
                      onMouseOver={() => setIsSlideBtnHovered(true)}
                      onMouseOut={() => setIsSlideBtnHovered(false)}
                      onClick={() => setIsSlideBtnHovered(false)}
                    >
                      <Text
                        size={15}
                        themecolor="dark200"
                        dangerouslySetInnerHTML={{ __html: info }}
                      />
                    </SlideAdditionalInfo> */}
                  </SlideWrapper>
                  <SlideIconsWrapper>
                    {!lg && (
                      <ControlButton onClick={goPrev}>
                        <Icon
                          src={rectangleBtnLeft}
                          width={80}
                          alt="rectangle-btn"
                        />
                      </ControlButton>
                    )}
                    {!lg && (
                      <ControlButton onClick={goNext}>
                        <Icon
                          src={rectangleBtnRight}
                          width={80}
                          alt="rectangle-btn"
                        />
                      </ControlButton>
                    )}
                  </SlideIconsWrapper>
                </SwiperSlide>
              ))}
            </Swiper>
          </LazyLoad>
        </SwiperWrapper>
      </StyledContainer2>
    </Section>
  )
}

export default Details
