import React from 'react'
import styled, { css, keyframes } from 'styled-components'

const spin = keyframes`
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  background: ${({ theme, secondary }) =>
    secondary ? 'transparent' : theme.colors.primary};
  color: ${({ theme, secondary }) =>
    secondary ? theme.colors.primary : theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.mainFont};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.medium};
  border: 2px solid ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 4px;
  text-decoration: none;
  transition: 0.3s;
  text-align: center;
  float: left;
  clear: left;
  box-shadow: 0 1px 30px -5px rgba(0, 0, 0, 0.2);
  height: 45px;
  ${({ theme }) => theme.mq.xs} {
    height: 55px;
    padding: 16px 30px;
    font-size: ${({ theme }) => theme.fontSize.s};
  }
  ${({ noHover }) =>
    !noHover &&
    css`
      @media (hover: hover) {
        &:hover {
          background: ${({ theme, secondary }) =>
            secondary ? theme.colors.primary : 'transparent'};
          color: ${({ theme, secondary }) =>
            secondary ? theme.colors.white : theme.colors.primary};
          ${({ primaryLightHover }) =>
            primaryLightHover &&
            css`
              background: ${({ theme }) => theme.colors.primaryLight};
              border-color: ${({ theme }) => theme.colors.primaryLight};
              color: ${({ theme }) => theme.colors.dark};
            `}
        }
      }
    `}
  ${({ outlinePrimaryDark }) =>
    outlinePrimaryDark &&
    css`
      box-shadow: none;
      border: 2px solid ${({ theme }) => theme.colors.primary};
      background: transparent;
      color: ${({ theme }) => theme.colors.dark};
      ${({ noHover }) =>
        !noHover &&
        css`
          @media (hover: hover) {
            &:hover {
              background: ${({ theme }) => theme.colors.primary};
              color: ${({ theme }) => theme.colors.white};
            }
          }
        `}
    `}
  ${({ submit }) =>
    submit &&
    css`
      position: relative;
      ${({ noHover }) =>
        !noHover &&
        css`
          @media (hover: hover) {
            &:hover {
              color: ${({ theme }) => theme.colors.primary};
              border-color: ${({ theme }) => theme.colors.primary};
            }
          }
        `}
      pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 24px;
        height: 24px;
        border: 3px solid ${({ theme }) => theme.white};
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-radius: 50%;
        opacity: ${({ disabled }) => (disabled ? '1' : '0')};
        animation: ${spin} 1s ease infinite;
      }
    `}
`

export default Button

const ViewButtonWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 5px;
  max-width: 190px;
  height: 72px;
  border-radius: 30px;
  transition: 0.3s;
  background: transparent;
  cursor: pointer;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.gray};
    font-size: ${({ theme }) => theme.fontSize.xs};
    white-space: nowrap;
    margin-left: 9px;

    & > div {
      margin-left: 12px;
    }
  }
  ${({ active }) =>
    active &&
    css`
      span {
        color: ${({ theme }) => theme.colors.dark200};
      }
    `}
`

export const ViewButton = ({ children, list, ...props }) => (
  <ViewButtonWrapper {...props}>
    <span>{children}</span>
  </ViewButtonWrapper>
)
