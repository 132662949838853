import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

export const InputItem = styled.div`
  position: relative;
  width: 100%;
`

export const Label = styled.label`
  position: absolute;
  top: -27px;
  left: 0;
  transition: 0.2s ease-out all;
  color: ${({ theme }) => theme.colors.dark};
  font-size: ${({ theme }) => theme.fontSize.s};
  cursor: auto;
  pointer-events: none;
  ${({ theme }) => theme.mq.xs} {
    font-size: ${({ theme }) => theme.fontSize.m};
  }
  span {
    color: ${({ theme }) => theme.colors.primary};
  }
  ${({ invalid }) =>
    invalid &&
    css`
      color: ${({ theme }) => theme.colors.red};
    `}
  ${({ asPlaceholder }) =>
    asPlaceholder &&
    css`
      top: 13px;
      left: 15px;
      opacity: 0;
      visibility: hidden;
      transition: none;
      font-size: ${({ theme }) => theme.fontSize.s} !important;
    `}
`

const StyledInput = styled.input`
  width: 100%;
  padding: 10px 15px;
  height: ${({ textarea }) => (textarea ? '80px' : '45px')};
  background: transparent;
  font-family: ${({ theme }) => theme.fonts.mainFont};
  font-size: ${({ theme }) => theme.fontSize.s};
  border: 1px solid #bebebe;
  transition: 0.3s;
  resize: none;
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }
  ${({ invalid }) =>
    invalid &&
    css`
      border-color: ${({ theme }) => theme.colors.red} !important;
    `}
  ${({ labelAsPlaceholder }) =>
    labelAsPlaceholder &&
    css`
      &:placeholder-shown + ${Label} {
        opacity: 1;
        visibility: visible;
      }
      ${({ invalid }) =>
        invalid &&
        css`
          transition: none;
          color: transparent;
          & + ${Label} {
            opacity: 1;
            visibility: visible;
          }
          &:focus {
            color: ${({ theme }) => theme.colors.dark};
            & + ${Label} {
              opacity: 0;
              visibility: hidden;
            }
          }
        `}
    `}
`

const FormInput = ({
  id,
  onChangeFn,
  onBlurFn,
  value,
  touched,
  errors,
  label,
  textarea,
  required,
  labelAsPlaceholder,
}) => {
  const labelText =
    (errors && touched && errors) ||
    (required ? `${label}<span>*</span>` : label)

  return (
    <InputItem>
      <StyledInput
        type="text"
        as={textarea ? 'textarea' : 'input'}
        textarea={textarea}
        rows="6"
        id={id}
        name={id}
        placeholder=" "
        onChange={onChangeFn}
        onBlur={onBlurFn}
        value={value}
        invalid={Boolean(touched && errors)}
        labelAsPlaceholder={labelAsPlaceholder}
      />
      <Label
        asPlaceholder={labelAsPlaceholder}
        dangerouslySetInnerHTML={{ __html: labelText }}
        htmlFor={id}
        invalid={Boolean(touched && errors)}
      />
    </InputItem>
  )
}

FormInput.propTypes = {
  id: PropTypes.string.isRequired,
  onChangeFn: PropTypes.func.isRequired,
  onBlurFn: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  touched: PropTypes.bool,
  errors: PropTypes.bool,
  textarea: PropTypes.bool,
  required: PropTypes.bool,
  labelAsPlaceholder: PropTypes.bool,
}

FormInput.defaultProps = {
  errors: false,
  touched: false,
  textarea: false,
  required: false,
  labelAsPlaceholder: false,
}

export default FormInput
