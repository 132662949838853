import slugify from 'slugify'

class Investment {
  constructor(data) {
    this.investmentName = String(data.investmentName) // Name of the investment
    this.flatsURL = String(data.flatsURL) // URL to flats API
    this.mailerURL = String(data.mailerURL) // URL to mailer
    this.buildings = new Map(data.buildings) // Map of Building items
    this.buildingsMap = data.buildingsMap // SVG map of buildings
    this.buildingsBackground = data.buildingsBackground // Background of buildings
    this.url = slugify(this.investmentName, { lower: true }) // route url
    this.multiBuilding = Boolean(this.buildings.size > 1)
  }
}

export default Investment
