import React from 'react'
import styled from 'styled-components'
// @ts-ignore
import Browser from 'browser/index'
// @ts-ignore
import investment from 'browser/investments/dolina-sanu/index'
import { Heading } from 'components/shared/typography'

import LazyLoad from 'react-lazyload'

type Props = {
  title: string
  flats: any[]
}

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Flats: React.FC<Props> = ({ title, flats }) => {
  return (
    <Section title={title} id="mieszkania">
      <LazyLoad
        offset={500}
        height="125vh"
        style={{
          width: '100%',
        }}
        once
      >
        <Heading
          as="h3"
          size={42}
          weight={500}
          margin="20px"
          themecolor="dark100"
          align="center"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Browser investment={investment} allFlats={flats} />
      </LazyLoad>
    </Section>
  )
}

export default Flats
