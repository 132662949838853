import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'
import reducer from './reducer'
import generateGetters from './getters'

if (typeof window !== 'undefined') {
  window.$ = $
  window.jQuery = $
}

const getFavourites = (investment) => {
  if (typeof localStorage !== 'undefined') {
    const savedFavs = localStorage.getItem(`${investment}-favourites`)
    return savedFavs ? JSON.parse(savedFavs) : []
  }
  return []
}

const getSpriteTime = () => {
  if (typeof window !== 'undefined' && window.innerWidth < 992) {
    return 'mobile'
  }
  return 'day'
}

const store = createContext()

const { Provider } = store

const initialState = {
  currentBuilding: null,
  currentFloor: null,
  currentFlat: null,
  currentUrl: '/',
  layoutHeight: '100vh',
  isTourVisible: false,
  isSidebarVisible: false,
  filters: {
    statuses: {
      all: [],
      selected: [],
    },
    stages: {
      all: [],
      selected: [],
    },
    rooms: {
      all: [],
      selected: [],
    },
    floors: {
      all: [],
      selected: [],
    },
    area: {
      extremes: [0, 0],
      values: [0, 0],
    },
    price: {
      extremes: [0, 0],
      values: [0, 0],
    },
  },
}

const StateProvider = ({ outerState, children }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...outerState,
    flats: outerState.allFlats,
    favourites: getFavourites(outerState.investment.url),
    spriteTime: getSpriteTime(),
  })

  return (
    <Provider
      value={{
        state,
        dispatch,
        getters: generateGetters(state),
      }}
    >
      {children}
    </Provider>
  )
}

StateProvider.propTypes = {
  outerState: PropTypes.objectOf(PropTypes.any).isRequired,
  children: PropTypes.node.isRequired,
}

export { store, StateProvider as Provider }
