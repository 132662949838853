/* eslint-disable */
const getParser = (field) => {
  switch (field) {
    case 'area':
    case 'price':
    case 'balcony':
    case 'floor':
    case 'rooms':
    case 'status':
      return (x) => Number(x[field])
    default:
      return (x) => x[field].toUpperCase()
  }
}

const sortBy = (field, isReversed) => {
  const key = getParser(field)

  const reverse = !isReversed ? 1 : -1

  return (a, b) => {
    return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a))
  }
}

export default sortBy
