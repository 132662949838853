import { useEffect, useRef } from 'react'

const usePortal = (selector) => {
  const rootElemRef = useRef(
    typeof window !== `undefined` ? document.createElement('div') : null,
  )

  useEffect(
    function setupElement() {
      const parentElem = document.querySelector(selector)
      parentElem.appendChild(rootElemRef.current)
      return function removeElement() {
        rootElemRef.current.remove()
      }
    },
    [selector],
  )

  return rootElemRef.current
}

export default usePortal
