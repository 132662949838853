import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import LazyImage from 'components/shared/lazyImage'
import Icon from 'components/shared/icon'

import { ImageDataLike } from 'gatsby-plugin-image'
import { Heading, Text } from 'components/shared/typography'

import investmentLogo from 'assets/icons/logo-3.svg'
import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  title: string
  description: string
  companyLogo: ImageDataLike
}

const Section = styled.section`
  width: 100%;
  padding: 0 0 80px;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.media.lg.min} {
    padding: 60px 0 140px;
  }
`

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Text} {
    max-width: 560px;
  }

  ${({ theme }) => theme.media.lg.min} {
    ${Text} {
      max-width: 900px;
    }
  }
`

const LogosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row;
    justify-content: space-between;
    width: 460px;
    margin-bottom: 80px;
  }
`

const SingleLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;

  // Gatsby image wrapper
  & > div {
    max-width: 225px;
  }

  ${({ theme }) => theme.media.lg.min} {
    margin-bottom: 0;
  }
`

const AboutCompany: React.FC<Props> = ({ title, description, companyLogo }) => {
  const { lg } = useBreakpoint()

  return (
    <Section title={title.replace(/<br\/> /g, '')}>
      <StyledContainer>
        <LogosWrapper>
          <SingleLogoWrapper>
            <Icon src={investmentLogo} width={90} alt="W-dolinie-sanu-logo" />
          </SingleLogoWrapper>
          <SingleLogoWrapper>
            <LazyImage src={companyLogo} alt="Borstar-logo" />
          </SingleLogoWrapper>
        </LogosWrapper>
        <Heading
          size={lg ? 42 : 34}
          themecolor="dark"
          align="center"
          margin={lg ? '50px' : '30px'}
          transform="uppercase"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Text
          size={lg ? 18 : 16}
          themecolor="dark"
          align="center"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </StyledContainer>
    </Section>
  )
}

export default AboutCompany
