import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import LazyImage from 'components/shared/lazyImage'
import Icon from 'components/shared/icon'
import { Heading, Text } from 'components/shared/typography'

import SwiperCore from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

import { ImageDataLike } from 'gatsby-plugin-image'

import rectangleBtnLeft from 'assets/icons/rectangle-button-left.svg'
import rectangleBtnRight from 'assets/icons/rectangle-button-right-2.svg'
import rectangleDark from 'assets/icons/rectangle-dark.svg'
import rectangleGray from 'assets/icons/rectangle-gray.svg'
import rectangleLight from 'assets/icons/rectangle-light.svg'
import elevatorIcon from 'assets/icons/elevator.svg'
import balconyIcon from 'assets/icons/balcony.svg'
import parkingIcon from 'assets/icons/parking.svg'

import useBreakpoint from 'hooks/useBreakpoint'

type Slide = {
  src: ImageDataLike
  label: string
  icon: string
  alt: string
}

type Props = {
  title: string
  gallery: Slide[]
  cloudImg: ImageDataLike
  cloudImg2: ImageDataLike
}

const getIcon = (icon: string) => {
  switch (icon) {
    case 'elevator':
      return elevatorIcon
    case 'balcon':
      return balconyIcon
    case 'parking':
      return parkingIcon
    default:
      return ''
  }
}

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 155px 0 130px 0;
  background-color: ${({ theme }) => theme.colors.primary};
  position: relative;
  overflow: hidden;

  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row;
    padding-left: ${({ theme }) => theme.container.paddingMD};
    overflow: hidden;
  }

  ${({ theme }) => theme.media.xxl.min} {
    padding: 155px 0 155px 80px;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 500px;
  padding-left: ${({ theme }) => theme.container.paddingMD};

  ${({ theme }) => theme.media.sm.min} {
    flex-direction: column;
    padding-left: 0;
  }

  ${({ theme }) => theme.media.lg.min} {
    margin-right: 80px;
  }
`

const SwiperWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  align-self: center;
  max-width: 100%;
  margin-top: 30px;
`

const SwiperTopLine = styled.div`
  position: absolute;
  top: 30px;
  left: 15%;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.dark100};
`

const SlideTopLine = styled.div`
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 500px;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.light};

  //Icons
  & > * {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  & > :first-child {
    transform: translate(-50%, -23px);
    z-index: 1;
  }
  & > :nth-child(2) {
    transform: translate(-50%, -7px);
  }
`

const SlideWrapper = styled.div<{ active: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 80px;
  z-index: 9;
  -webkit-overflow-scrolling: auto !important;

  // Img wrapper
  & > div:first-child {
    height: 100%;
  }

  ${({ theme }) => theme.media.lg.min} {
    margin-right: 20px;

    ${({ active }) =>
      !active &&
      css`
        & > ${SlideTopLine} {
          background-color: ${({ theme }) => theme.colors.dark100};
        }
      `}
  }
`

const SlideLabel = styled(Text)`
  position: absolute;
  top: 57.5%;
  left: 50%;
  transform: translate3d(-50%, 0, 100px);
  width: max-content;
  font-size: 17px;
  font-weight: 600;
  ${({ theme }) => theme.media.lg.min} {
    top: 50%;
  }
  ${({ theme }) => theme.media.xl.min} {
    top: 57.5%;
  }
`

const SlideIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 11px;
  left: 50%;
  transform: translate3d(-50%, 0, 100px);
  width: fit-content;
  z-index: 10;
`

const RectangleIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 3px;

  // Inner icon in rectangle
  & > :nth-child(2) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const ControlButtons = styled.div`
  display: flex;
  align-items: center;
`

const ControlButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const CloudWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -55%);
  width: 1000px;
  z-index: 2;

  ${({ theme }) => theme.media.sm.min} {
    transform: translate(-80%, -55%);
  }

  ${({ theme }) => theme.media.lg.min} {
    width: 1400px;
    transform: translate(-100%, -62%);
  }

  ${({ theme }) => theme.media.xxl.min} {
    width: 1600px;
    transform: translate(-118%, -62%);
  }
`

const CloudWrapper2 = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -55%);
  width: 1000px;
  z-index: 2;

  ${({ theme }) => theme.media.sm.min} {
    transform: translate(-40%, -55%);
  }

  ${({ theme }) => theme.media.lg.min} {
    width: 1400px;
    transform: translate(-65%, -62%);
  }

  ${({ theme }) => theme.media.xxl.min} {
    width: 1600px;
    transform: translate(-65%, -60%);
  }
`
const CloudWrapper3 = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-30%, -62%);
  width: 1400px;
  z-index: 2;

  ${({ theme }) => theme.media.xxl.min} {
    width: 1600px;
    transform: translate(-45%, -62%);
  }
`

const CloudWrapper4 = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-15%, -62%);
  width: 1600px;
  z-index: 2;
`

const CloudWrapper5 = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-37%, 65%);
  width: 1000px;
  z-index: 2;

  ${({ theme }) => theme.media.sm.min} {
    transform: translate(-51%, 70%);
    width: 1250px;
  }

  ${({ theme }) => theme.media.lg.min} {
    width: 1400px;
    transform: translate(-75%, 60%);
  }

  ${({ theme }) => theme.media.xxl.min} {
    width: 1600px;
    transform: translate(-83%, 60%);
  }
`

const CloudWrapper6 = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-37%, 65%);
  width: 1000px;
  z-index: 2;

  ${({ theme }) => theme.media.sm.min} {
    transform: translate(-29%, 70%);
    width: 1250px;
  }

  ${({ theme }) => theme.media.lg.min} {
    width: 1400px;
    transform: translate(-39%, 60%);
  }

  ${({ theme }) => theme.media.xxl.min} {
    width: 1600px;
    transform: translate(-48%, 60%);
  }
`

const CloudWrapper7 = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(0%, 67%);
  width: 1400px;
  z-index: 2;

  ${({ theme }) => theme.media.xxl.min} {
    width: 1600px;
    transform: translate(-12%, 67%);
  }
`

const CloudWrapper8 = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(15%, 67%);
  width: 1600px;
  z-index: 2;
`

const Benefits: React.FC<Props> = ({ title, gallery, cloudImg, cloudImg2 }) => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null)
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(3)

  const { lg, xxl } = useBreakpoint()
  const slidesPerViewDesktop = 3

  const goPrev = () => {
    swiper?.slidePrev()
  }

  const goNext = () => {
    swiper?.slideNext()
  }

  useEffect(() => {
    setActiveSlideIndex(0)
  }, [])

  return (
    <Section title={title.replace(/<br\/> /g, '')} id="okolica">
      <CloudWrapper>
        <LazyImage src={lg ? cloudImg2 : cloudImg} alt="cloud" />
      </CloudWrapper>
      <CloudWrapper2>
        <LazyImage src={lg ? cloudImg2 : cloudImg} alt="cloud" />
      </CloudWrapper2>
      {lg && (
        <CloudWrapper3>
          <LazyImage src={cloudImg2} alt="cloud" />
        </CloudWrapper3>
      )}
      {xxl && (
        <CloudWrapper4>
          <LazyImage src={cloudImg2} alt="cloud" />
        </CloudWrapper4>
      )}
      <Header>
        <Heading
          as="h3"
          size={42}
          weight={500}
          margin="20px"
          themecolor="dark100"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        {lg && (
          <ControlButtons>
            <ControlButton onClick={goPrev}>
              <Icon src={rectangleBtnLeft} width={80} alt="rectangle-btn" />
            </ControlButton>
            <ControlButton onClick={goNext}>
              <Icon src={rectangleBtnRight} width={80} alt="rectangle-btn" />
            </ControlButton>
          </ControlButtons>
        )}
      </Header>
      <SwiperWrapper>
        {lg && <SwiperTopLine />}
        <Swiper
          spaceBetween={0}
          slidesPerView={lg ? slidesPerViewDesktop : 1}
          onSwiper={(initSwiper) => setSwiper(initSwiper)}
          // @ts-ignore
          onSlideChange={() => setActiveSlideIndex(swiper?.realIndex)}
          loop
          speed={1000}
        >
          {gallery.map(({ src, label, icon, alt }, index) => (
            <SwiperSlide key={index}>
              <SlideWrapper active={activeSlideIndex === index}>
                <LazyImage src={src} alt={alt} />
                <SlideLabel
                  themecolor="white"
                  dangerouslySetInnerHTML={{ __html: label }}
                />
                <SlideTopLine>
                  <Icon
                    src={
                      lg && activeSlideIndex !== index
                        ? rectangleDark
                        : rectangleLight
                    }
                    width={25}
                    alt="rectangle"
                  />
                  <Icon
                    src={
                      lg && activeSlideIndex !== index
                        ? rectangleGray
                        : rectangleDark
                    }
                    width={25}
                    alt="rectangle"
                  />
                </SlideTopLine>
              </SlideWrapper>
              <SlideIconsWrapper>
                {!lg && (
                  <ControlButton onClick={goPrev}>
                    <Icon
                      src={rectangleBtnLeft}
                      width={80}
                      alt="rectangle-btn"
                    />
                  </ControlButton>
                )}
                <RectangleIconWrapper>
                  <Icon
                    src={icon === 'elevator' ? rectangleDark : rectangleGray}
                    width={100}
                    alt="rectangle"
                  />
                  <Icon src={getIcon(icon)} width={30} alt="" />
                </RectangleIconWrapper>
                {!lg && (
                  <ControlButton onClick={goNext}>
                    <Icon
                      src={rectangleBtnRight}
                      width={80}
                      alt="rectangle-btn"
                    />
                  </ControlButton>
                )}
              </SlideIconsWrapper>
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperWrapper>
      <CloudWrapper5>
        <LazyImage src={lg ? cloudImg : cloudImg2} alt="cloud" />
      </CloudWrapper5>
      <CloudWrapper6>
        <LazyImage src={lg ? cloudImg : cloudImg2} alt="cloud" />
      </CloudWrapper6>
      {lg && (
        <CloudWrapper7>
          <LazyImage src={cloudImg2} alt="cloud" />
        </CloudWrapper7>
      )}
      {xxl && (
        <CloudWrapper8>
          <LazyImage src={cloudImg2} alt="cloud" />
        </CloudWrapper8>
      )}
    </Section>
  )
}

export default Benefits
