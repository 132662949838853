import { useEffect, useState } from 'react'
import { breakpoints } from 'browser/styled/theme'

const useMedia = (breakpoint, initState = true) => {
  const [matches, setMatches] = useState(initState)

  useEffect(() => {
    const media = window.matchMedia(`(min-width: ${breakpoints[breakpoint]}px)`)
    if (media.matches !== matches) setMatches(media.matches)

    const listener = () => setMatches(media.matches)
    media.addListener(listener)

    return () => media.removeListener(listener)
  }, [breakpoint, matches])

  return matches
}

export const useCustomMedia = (query) => {
  const [matches, setMatches] = useState(true)

  useEffect(() => {
    const media = window.matchMedia(query)
    if (media.matches !== matches) setMatches(media.matches)

    const listener = () => setMatches(media.matches)
    media.addListener(listener)

    return () => media.removeListener(listener)
  }, [query, matches])

  return matches
}

export default useMedia
