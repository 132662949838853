import React, { useState, useContext, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Button from 'browser/components/Button'
import Input from 'browser/components/Form/Input'
import Terms from 'browser/components/Form/Terms'
import { Formik, Form } from 'formik'
// import { store } from 'browser/store'
import * as Yup from 'yup'
// import axios from 'axios'
import { LangContext } from 'browser/contexts/LangContext'
import useOutsideClick from 'browser/hooks/useOutsideClick'
// import getYLFormParams from 'browser/utils/getYLFormParams'

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  background: rgba(255, 255, 255, 0.6);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    `}
`

const FormWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 30px 30px 5px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 2px 2px 45px rgba(0, 0, 0, 0.15);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s cubic-bezier(0.5, 0.32, 0.11, 1);
  transform: scale(0.9);
  max-height: 620px;
  overflow-y: auto;
  @media (max-width: 439px) {
    max-width: 100vw;
    padding: 27px 15px 5px;
    transform: translateX(100vw);
    height: 100%;
    transition: 0.4s cubic-bezier(0.5, 0.32, 0.11, 1);
    max-height: none;
  }
  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      visibility: visible;
      transform: scale(1) translateX(0) !important;
      will-change: transform;
    `};
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    margin-bottom: 4px;
  }
`

const Heading = styled.h3`
  font-size: 2.4rem;
  font-weight: ${({ theme }) => theme.semiBold};
  margin-bottom: 25px;
  @media (max-width: 360px) {
    font-size: 2.2rem;
  }
`

const TermsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  label {
    :first-of-type {
      margin: 10px 0 4px;
    }
    margin: 2px 0;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  padding-top: 25px;
  button {
    width: 100%;
    box-shadow: none;
    margin-bottom: 25px;
  }
`

const ErrorTerms = styled.p`
  margin: 8px 0 -10px;
  text-align: left;
  color: ${({ theme }) => theme.colors.red};
  font-size: ${({ theme }) => theme.fontSize.s};
`

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  transition: 0.3s;
  background: ${({ theme }) => theme.colors.white300};
  padding: 12px;
  @media (max-width: 360px) {
    right: 15px;
  }
  svg {
    width: 100%;
    height: 100%;
    transition: 0.3s;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.primary};
    svg {
      fill: #fff;
    }
  }
`

const Message = styled.p`
  margin: 12px 0 -8px;
  text-align: left;
  color: ${({ theme, error }) =>
    error ? theme.colors.red : theme.colors.primary};
`

// const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g;

const FlatForm = ({
  heading,
  flat,
  investment,
  isVisible,
  closeForm,
  bookFlat,
}) => {
  // const {
  //   state: {
  //     investment: { mailerURL },
  //   },
  // } = useContext(store)
  const [formMessage, setFormMessage] = useState({ text: '', error: false })
  const [shouldRender, setShouldRender] = useState(false)
  const [submitClicked, setSubmitClicked] = useState(false)
  const { translate, lang } = useContext(LangContext)
  const formikRef = useRef(null)

  const contactSchema = Yup.object().shape({
    name: Yup.string().required(translate('nameErrorReq')),
    // surname: Yup.string().required(translate('surnameErrorReq')),
    email: Yup.string()
      .email(translate('emailErrorInvalid'))
      .required(translate('emailErrorReq')),
    phone: Yup.string()
      .min(9, translate('phoneErrorInvalid'))
      .matches(
        /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
        translate('phoneErrorInvalid'),
      )
      .required(translate('phoneErrorReq')),
    // message: Yup.string().required(translate('messageErrorReq')),
    agree1Browser: Yup.bool().oneOf([true], translate('termsErrorReq')),
  })

  const contactInitialValues = {
    name: '',
    surname: '',
    email: '',
    phone: '',
    message: '',
    agree1Browser: false,
    agree2Browser: false,
    agree3Browser: false,
    agree4Browser: false,
  }

  useEffect(() => {
    if (isVisible) setShouldRender(true)
    else setTimeout(() => setShouldRender(false), 500)
  }, [isVisible])

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.resetForm()
      const flatInvestmentMessage = {
        pl: `Dzień dobry, interesuje mnie mieszkanie nr ${flat} w inwestycji ${investment}. Proszę o kontakt.`,
        en: `Hello, I'm interested in apartment no. ${flat} in the ${investment} investment. Please contact me.`,
      }
      const flatMessage = {
        pl: `Jestem zainteresowany mieszkaniami o numerach: ${flat}.`,
        en: `I'm interested in apartments with numbers: ${flat}.`,
      }
      const flatBookMessage = {
        pl: `Proszę o rezerwację mieszkania ${flat}`,
        en: `Please reserve an apartment ${flat}`,
      }
      if (flat && investment) {
        formikRef.current.setFieldValue(
          'message',
          bookFlat ? flatBookMessage[lang] : flatInvestmentMessage[lang],
        )
      } else if (flat) {
        formikRef.current.setFieldValue('message', flatMessage[lang])
      } else {
        formikRef.current.setFieldValue('message', ``)
      }
    }
  }, [flat, investment, lang, bookFlat])

  const modalRef = useRef(null)
  useOutsideClick(modalRef, closeForm, null, isVisible)

  return (
    <Formik
      innerRef={formikRef}
      initialValues={contactInitialValues}
      validationSchema={contactSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        ;(async () => {
          const {
            name,
            surname,
            email,
            phone,
            message,
            agree1Browser: agree1,
            agree2Browser: agree2,
            agree3Browser: agree3,
            agree4Browser: agree4,
          } = values

          let params = {
            name,
            surname,
            agree1,
            agree2,
            agree3,
            agree4,
            source: bookFlat
              ? `Wyszukiwarka - REZERWACJA ${flat}`
              : `Wyszukiwarka - ${flat}`,
            url: window.location.href,
          }
          params = email ? { ...params, email } : params
          params = phone ? { ...params, phone } : params
          params = message ? { ...params, message } : params
          if (agree1 === false) return setSubmitting(false)
          try {
            setSubmitting(true)
            // const { data } = await axios.get(mailerURL, {
            //   params,
            // });
            // const formID = bookFlat ? 'reserve-form' : 'contact-form-flat';
            // Monitor.TrackForm(formID, 3, 3, getYLFormParams(params));

            window.dataLayer.push({
              event: 'formularz',
              mieszkanie: flat || '',
            })
            setSubmitting(false)
            setFormMessage({
              // text: data.message,
              text: 'Wiadomość została wysłana pomyślnie!',
              error: false,
            })

            setTimeout(() => {
              setFormMessage({ text: '', error: false })
              resetForm()
            }, 10000)
          } catch (err) {
            setSubmitting(false)
            setFormMessage({
              text: 'Coś poszło nie tak!',
              error: true,
            })

            setTimeout(() => {
              setFormMessage({ text: '', error: false })
            }, 2500)
          }
        })()
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setErrors,
        isSubmitting,
      }) => (
        <Overlay isVisible={isVisible}>
          {(shouldRender || isVisible) && (
            <FormWrapper isVisible={isVisible} ref={modalRef}>
              <CloseButton onClick={closeForm}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                >
                  <path
                    d="M12.215,11l9.533-9.533A.859.859,0,1,0,20.533.252L11,9.785,1.467.252A.859.859,0,0,0,.251,1.468L9.784,11,.251,20.534a.859.859,0,0,0,1.215,1.215L11,12.216l9.533,9.533a.859.859,0,0,0,1.215-1.215Z"
                    transform="translate(0 -0.001)"
                  />
                </svg>
              </CloseButton>
              <Heading>{heading}</Heading>
              <Form
                onSubmit={(formValues) => {
                  handleSubmit(formValues)
                  setSubmitClicked(true)
                }}
              >
                <Column>
                  <Input
                    id="name"
                    label={translate('name')}
                    required
                    labelAsPlaceholder
                    onChangeFn={handleChange}
                    onBlurFn={handleBlur}
                    value={values.name}
                    touched={touched.name}
                    errors={submitClicked && errors.name}
                  />
                  <Input
                    id="surname"
                    label={translate('surname')}
                    // required
                    labelAsPlaceholder
                    onChangeFn={handleChange}
                    onBlurFn={handleBlur}
                    value={values.surname}
                    touched={touched.surname}
                    errors={submitClicked && errors.surname}
                  />
                  <Input
                    id="email"
                    label={translate('email')}
                    required
                    labelAsPlaceholder
                    onChangeFn={handleChange}
                    onBlurFn={handleBlur}
                    value={values.email}
                    touched={touched.email}
                    errors={submitClicked && errors.email}
                  />
                  <Input
                    id="phone"
                    label={translate('phone')}
                    required
                    labelAsPlaceholder
                    onChangeFn={handleChange}
                    onBlurFn={handleBlur}
                    value={values.phone}
                    touched={touched.phone}
                    errors={submitClicked && errors.phone}
                  />
                </Column>
                <Input
                  textarea
                  // required
                  labelAsPlaceholder
                  id="message"
                  label={translate('message')}
                  onChangeFn={handleChange}
                  onBlurFn={handleBlur}
                  value={values.message}
                  touched={touched.message}
                  errors={submitClicked && errors.message}
                />
                <TermsWrapper>
                  <Terms
                    setFieldValue={setFieldValue}
                    values={values}
                    setErrors={setErrors}
                    errors={errors}
                    submitClicked={submitClicked}
                  />
                </TermsWrapper>
                {submitClicked && errors.agree1Browser && (
                  <ErrorTerms>{errors.agree1Browser}</ErrorTerms>
                )}
                {formMessage.text && (
                  <Message error={formMessage.error}>
                    {formMessage.text}
                  </Message>
                )}
                <ButtonWrapper>
                  <Button
                    submit
                    type="submit"
                    disabled={isSubmitting}
                    isSubmitting={isSubmitting}
                  >
                    {!isSubmitting && translate('sendMessage')}
                  </Button>
                </ButtonWrapper>
              </Form>
            </FormWrapper>
          )}
        </Overlay>
      )}
    </Formik>
  )
}

FlatForm.propTypes = {
  heading: PropTypes.string.isRequired,
  flat: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  closeForm: PropTypes.func.isRequired,
  investment: PropTypes.string,
  bookFlat: PropTypes.bool,
}

FlatForm.defaultProps = {
  investment: '',
  bookFlat: false,
}

export default FlatForm
