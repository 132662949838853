export default {
  en: {
    // Globals
    allFlats: 'Number of apartments',
    available: 'Available',
    loading: 'Loading',
    loading_building: 'Loading 3D module',
    loading_tour: 'Loading virtual tour',
    back: 'Back',
    back2: 'Back',

    // Topbar
    choose_city: 'Choose city',
    choose_investment: 'Choose investment',
    choose_building: 'Choose building',
    choose_floor: 'Choose floor',
    choose_flat: 'Choose flat',
    wishlist: 'Wishlist',

    // Breadcrumbs
    building: 'Building',
    stage: 'Stage',
    floor: 'Floor',
    groundFloorShort: 'GF',

    // Legend
    room1: '1 room',
    room2: '2 rooms',
    room3: '3 rooms',
    room4: '4 rooms',
    room5: '5 rooms',

    // Flat
    from: 'from',
    to: 'to',
    apartment: 'Unit',
    flat: 'Apartment',
    local: 'Commercial Unit',
    investment: 'Investment',
    interior: 'Interior',
    surroundings: 'Surroundings',
    number: 'number',
    area: 'Area',
    rooms: 'Rooms',
    balcony: 'Balcony',
    status: 'Status',
    sale: 'Sale',
    status_available: 'Available',
    status_unavailable: 'Unavailable',
    status_reserved: 'Reserved',
    status_sold: 'Sold',
    status_planned: 'Planned',
    hideFilters: 'Hide filters',

    homepage: 'Home',
    flats: 'Apartments',
    warszawa: 'Warsaw',
    krakow: 'Cracow',
    warszawa_locative: 'Apartments in Warsaw',
    warszawa_locative2: 'Apartments<br/>in Warsaw',
    krakow_locative: 'Apartments in Cracow',
    krakow_locative2: 'Apartments<br/>in Cracow',
    find_apartments_in_warszawa: 'Find apartment in Warsaw',
    find_apartments_in_krakow: 'Find apartment in Cracow',
    browser3D: '3D Browser',
    askForOffer: 'Ask for offer',
    listOfApartments: 'List of apartments',
    findApartment: 'Find an apartment',
    surface: 'Area',
    city: 'City',
    district: 'District',
    numberOfRooms: 'Rooms',
    price: 'Price',
    chooseFromList: 'Choose from the list...',
    localization: 'Location',
    availableFlats: 'Available apartments',
    investments: 'Investments',
    filterFlats: 'Filter apartments',
    removeFilters: 'Clear filters',
    removeAll: 'Remove all',
    flatsInWishlist: 'Wishlist apartments',
    noWishlistFlats: 'No wishlist apartments',
    day: 'Day',
    night: 'Night',
    investmentMap: 'Investment map',
    investmentGallery: 'Investment gallery',
    gallery: 'Gallery',
    loadingMap: `Loading map`,
    loadingFlats: `Loading apartments`,

    // Form
    acceptAll: 'Check all',
    name: 'First name',
    surname: 'Surname',
    email: 'Email address',
    phone: 'Phone number',
    message: 'Message',
    nameErrorReq: 'First name is required',
    surnameErrorReq: 'Surname is required',
    emailErrorReq: 'Email address is required',
    emailErrorInvalid: 'Email address is invalid',
    phoneErrorReq: 'Phone number is required',
    phoneErrorInvalid: 'Phone number is invalid',
    messageErrorReq: 'Message is required',
    termsErrorReq: 'Accept required terms',
    roomsMessage: 'How many rooms?',
    sendMessage: 'Send message',
    errorMessage: 'Something went wrong',
    successMessage: 'Your message has been sent!',
    more: 'More',
    less: 'Less',

    // Navigation
    apartments: 'Apartments',
    aboutMatexi: 'About Matexi',
    welcomeMatexi: 'Welcome to the neighbourhood',
    logBuilding: 'Construction logbook',
    contact: 'Contact',
    matexiGroup: 'Matexi Group',
    matexiPoland: 'Matexi Polska',
    saleOffices: 'Offices',
    forTheMedia: 'For the media',
    completedProjects: 'Completed projects',
    plannedProjects: 'Planned projects',
    commercialUnits: 'Commercial units',
    allRightsReserved: '© 2021 Matexi Polska All Rights Reserved.',
    cookies: 'Cookies',
    privacyPolicy: 'Privacy policy',
    madeBy: 'Made by',
    weAreMemberOf: 'We are a member of',
    writeToUs: 'Write to us',

    allLabel: 'All',
    findOutMore: 'Find out more',
    chooseShort: 'Choose...',
    noPosts: 'No posts',
    noInvestments: 'No investments',
    findYourFlat: 'Find your apartment',
    showMyLayouts: 'Show my matching floor plans',
    showAllLayouts: 'Show all floor plans',
    cardView: 'Card view',
    cards: 'Cards',
    listView: 'List view',
    list: 'List',
    askAboutFlats: 'Inquiry form',
    noMatchingFlats: 'There is no flat matching your criteria',
    seeMore: 'See more',
    seeAllInvestments: 'See all projects',
    investmentsMap3D: 'Project map',
    areYouInterested: 'Are you interested in living at this residence?',
    areYouInterestedInvestment:
      'Are you interested in living at this investment?',
    areYouInterestedExt:
      'Are you interested in living at this residence?<br/>Contact us',
    ask: 'Inquire',
    call: 'Call',
    pageNotFound: 'Page not found',

    // Flat
    flatView: "Flat's view",
    floorView: "Floor's view",
    tour3d: 'Virtual Tour 3D',
    addToWishlist: 'Add to wishlist',
    removeFromWishlist: 'Remove from wishlist',
    downloadPDF: 'Download',
    book: 'Reserve',
    askAboutFlat: 'Inquiry form',
    askAboutInvestment: 'Inquiry form',
    bookFlat: 'Reserve an apartment',
    checkOut: 'Read more',
    goBackToFlat: 'Go back',
    noPreview: 'No preview',
    nr: 'No.',
    theSameLayout: 'The same <br />floor layout:',
    chooseFromSimilars: 'Choose out of similar apartments',
    remove: 'Remove',
    add: 'Add',
    fromWishlist: 'from',
    toWishlist: 'to',
    wishlistLocative: 'wishlist',
  },
}
