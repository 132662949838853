/* eslint-disable no-undef */
import React, { useState } from 'react'
import { withProps, compose } from 'recompose'
import styled, { keyframes } from 'styled-components'
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from 'react-google-maps'

import transportIcon from 'assets/icons/pin-transport.png'
import sportIcon from 'assets/icons/pin-sport.png'
import shopIcon from 'assets/icons/pin-shop.png'
import restaurantIcon from 'assets/icons/pin-restaurant.png'
import natureIcon from 'assets/icons/pin-nature.svg'
import logoIcon from 'assets/icons/pin-logo.svg'

import type { Pin } from 'components/layout/Location/index'

type MapPin = Pin & { visible: boolean }

type Props = {
  pins: MapPin[]
}

const getIcon = (type: string) => {
  switch (type.toLowerCase()) {
    case 'transport':
      return transportIcon
    case 'sport':
      return sportIcon
    case 'sklepy':
      return shopIcon
    case 'restaurant':
      return restaurantIcon
    case 'nature':
      return natureIcon
    case 'investment':
      return logoIcon
    default:
      return null
  }
}

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
const mapStyles = [
  {
    featureType: 'road.highway',
    stylers: [
      {
        hue: '#FFC200',
      },
      {
        saturation: -61.8,
      },
      {
        lightness: 45.599999999999994,
      },
      {
        gamma: 1,
      },
    ],
  },
  {
    featureType: 'road.local',
    stylers: [
      {
        hue: '#FF0300',
      },
      {
        saturation: -100,
      },
      {
        lightness: 52,
      },
      {
        gamma: 1,
      },
    ],
  },
  {
    featureType: 'water',
    stylers: [
      {
        hue: '#0078FF',
      },
      {
        saturation: -13.200000000000003,
      },
      {
        lightness: 2.4000000000000057,
      },
      {
        gamma: 1,
      },
    ],
  },
]
const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`
const Loader = styled.figure`
  display: block;
  width: 50px;
  height: 50px;
  border: 4px solid ${({ theme }) => theme.colors.light};
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-radius: 50%;
  animation: ${spin} 1s ease infinite;
`

const MapLoader = () => (
  <Wrapper>
    <Loader />
  </Wrapper>
)

const MapContainer = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyDVDSmo7kVj4ENEtBemDPt8o2FwHHInmxg&libraries=geometry,drawing,places',
    loadingElement: <MapLoader />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
)((props: Props) => {
  const [logoVisible, setLogoVisible] = useState(true)

  return (
    <GoogleMap
      defaultZoom={15.1}
      defaultCenter={{
        lat: Number(props.pins[0].locationPinsLat),
        lng: Number(props.pins[2].locationPinsLng),
      }}
      defaultOptions={{ styles: mapStyles }}
    >
      {props.pins.map(
        ({ locationPinsLat, locationPinsLng, locationPinsType, visible }) => (
          <Marker
            onClick={() => {
              if (locationPinsType === 'investment') {
                setLogoVisible(false)
              }
            }}
            visible={locationPinsType == 'investment' ? logoVisible : visible}
            key={locationPinsLat}
            position={{
              lat: Number(locationPinsLat),
              lng: Number(locationPinsLng),
            }}
            icon={{
              url: getIcon(locationPinsType),
              anchor: new google.maps.Point(24, 48),
              scaledSize:
                locationPinsType === 'investment'
                  ? new google.maps.Size(52, 72)
                  : new google.maps.Size(30, 30),
            }}
          />
        ),
      )}
    </GoogleMap>
  )
})
export default MapContainer
