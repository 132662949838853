import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import App from 'browser/App'
import getFlatsWithProperties from 'browser/utils/getFlatsWithProperties'

const BrowserWrapper = ({
  allFlats,
  investment,
  loading,
  loadingText,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [flats, setFlats] = useState([])

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      console.log({ allFlats })
      const formatedFlats = await getFlatsWithProperties(allFlats)
      console.log({ formatedFlats })
      setFlats(formatedFlats)
      setIsLoading(false)
    })()
  }, [allFlats])

  return (
    <div id="browser" style={{ width: '100%' }}>
      <App
        allFlats={flats}
        investment={investment}
        isLoading={isLoading}
        loadingText={loadingText}
        {...props}
      />
    </div>
  )
}

BrowserWrapper.propTypes = {
  investment: PropTypes.objectOf(PropTypes.any).isRequired,
  allFlats: PropTypes.arrayOf(PropTypes.object),
  loadingText: PropTypes.string,
  loading: PropTypes.bool,
}

BrowserWrapper.defaultProps = {
  allFlats: null,
  loadingText: `Ładowanie mieszkań`,
  loading: true,
}

export default BrowserWrapper
