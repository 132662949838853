const getters = {
  getStatus(flat) {
    switch (flat.status) {
      case 1:
        return 'available'
      case 2:
        return 'reserved'
      default:
        return 'sold'
    }
  },

  getFlatsByFloor(floor) {
    const { flats } = this.state
    return flats.filter((flat) => flat.floor == floor)
  },

  getFlatsByBuilding(building) {
    const { allFlats } = this.state
    return allFlats.filter((flat) => flat.building == building)
  },

  getFlatsByBuildingAndFloor(building, floor) {
    const { allFlats, investment } = this.state
    if (investment.multiBuilding)
      return allFlats.filter(
        (flat) => flat.building == building && flat.floor == floor,
      )
    return this.getFlatsByFloor(floor)
  },

  getNotSoldFlats() {
    const { flats } = this.state
    return flats.filter((flat) => flat.status !== 3)
  },

  getFilteredByStatus(array, status) {
    return array.filter((flat) => flat.status === status)
  },

  getBuilding(building) {
    const { investment } = this.state
    return investment.buildings.get(`${building}`)
  },

  getFloor(building, floor) {
    if (building) {
      return building.floors.list.find((el) => el.level === floor)
    }
    return null
  },

  getFavouriteFlats() {
    const { allFlats, favourites } = this.state
    return favourites
      .map((favName) => allFlats.find(({ name }) => name === favName))
      .filter((el) => el)
  },
}

const generateGetters = (state) => {
  return { state, ...getters }
}

export default generateGetters
