import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'

import Gallery from 'components/layout/Construction/Gallery'

import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

import Container from 'components/shared/container'
import LazyImage from 'components/shared/lazyImage'
import Icon from 'components/shared/icon'
import { Heading, Text } from 'components/shared/typography'

import { ImageDataLike } from 'gatsby-plugin-image'

import rectangleDark from 'assets/icons/rectangle-dark.svg'
import rectangleLight from 'assets/icons/rectangle-light.svg'

import useBreakpoint from 'hooks/useBreakpoint'

import LazyLoad from 'react-lazyload'

import useOutsideClick from 'hooks/useOutsideClick'

type StageSlide = {
  src: ImageDataLike
  alt: string
  date: string
}

type StageSingle = {
  stageActive: boolean
  stageTitle: string
}

type Stage = {
  gallery: StageSlide[]
  stages: StageSingle[]
}

type Slide = {
  src: ImageDataLike
  alt: string
}

type Props = {
  title: string
  gallery: Slide[]
  buildingStage: number
  stages: Stage[]
  stages2: string[]
}

const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 60px;
`

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  max-width: 768px;

  ${({ theme }) => theme.media.md.min} {
    flex-direction: row;
    justify-content: space-between;
  }

  ${({ theme }) => theme.media.lg.min} {
    max-width: ${({ theme }) =>
      `calc(${theme.container.width} + (${theme.container.padding} * 2))`};
    margin-bottom: 50px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    max-width: ${({ theme }) =>
      `calc(${theme.container.widthXL} + (${theme.container.padding} * 2))`};
  }
`

const StagesButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 100%;
  max-width: 420px;
`

const StageButton = styled.button<{ active: boolean }>`
  max-width: 240px;
  width: 50%;
  height: 55px;
  background-color: ${({ theme }) => theme.colors.gray};
  font-size: 16px;
  color: ${({ theme }) => theme.colors.white};

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.secondary};
      color: ${({ theme }) => theme.colors.light};
    `}
`

const GalleryContainer = styled(Container)`
  padding: 0;

  ${({ theme }) => theme.media.lg.min} {
    padding-left: ${({ theme }) => theme.container.paddingMD};
    padding-right: ${({ theme }) => theme.container.paddingMD};
  }
`

const SwiperWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  align-self: center;
  max-width: 100%;
  margin-top: 30px;
`
const SlideWrapperOverlay = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000048;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  opacity: 0;
  transition: 0.2s;

  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
    `}
`

const SlideWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  padding-top: 150px;
  // Img wrapper
  & > div:first-child {
    position: relative;
  }
`

const SlideInnerWrapper = styled.div`
  // Gatsby image wrapper
  & > div:first-child {
    height: 100%;
    min-height: 300px;
  }
`

const StageIndicator = styled.div`
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 768px;
`

const StageIcons = styled.div`
  //Icon wrapper
  & > div {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  //Icon light
  & > div:first-child {
    top: -5px;
  }

  //Icon dark
  & > div:last-child {
    top: -15px;
  }
`

const SlideStageLine = styled.div`
  width: 100%;
  height: 3px;
  margin: 40px 0 30px;
  background-color: ${({ theme }) => theme.colors.light};
`

const Construction: React.FC<Props> = ({ title, stages }) => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null)

  const [activeStage, setActiveStage] = useState(0)
  const [dateVisible, setDateVisible] = useState(false)

  const { md, lg } = useBreakpoint()

  const swiperRef = useRef()

  const handleDateHide = () => setDateVisible(false)

  //@ts-ignore
  useOutsideClick({ ref: swiperRef, handler: handleDateHide })

  return (
    <Section title={title.replace(/<br\/> /g, '')} id="postep-prac">
      <StyledContainer>
        <Heading
          as="h3"
          size={42}
          weight={500}
          margin={md ? '0' : '40px'}
          themecolor="dark100"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <StagesButtons>
          <StageButton
            type="button"
            active={activeStage === 0}
            onClick={() => setActiveStage(0)}
          >
            Etap I
          </StageButton>
          <StageButton
            type="button"
            active={activeStage === 1}
            onClick={() => setActiveStage(1)}
          >
            Etap II
          </StageButton>
        </StagesButtons>
      </StyledContainer>
      <LazyLoad
        offset={500}
        height="100%"
        style={{
          width: '100%',
        }}
        once
      >
        <GalleryContainer>
          {lg ? (
            <Gallery
              gallery={stages[activeStage].gallery}
              stages={stages[activeStage].stages}
              initialImagesCount={8}
            />
          ) : (
            <SwiperWrapper ref={swiperRef}>
              <Swiper
                modules={[Autoplay]}
                spaceBetween={0}
                slidesPerView={1}
                onSwiper={(initSwiper) => setSwiper(initSwiper)}
                loop
                speed={1000}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
              >
                {stages[activeStage].gallery.map(
                  ({ src, alt, date }, index) => (
                    <SwiperSlide key={index}>
                      <SlideWrapper
                        onClick={() => setDateVisible(!dateVisible)}
                      >
                        <SlideInnerWrapper>
                          <LazyImage src={src} alt={alt} />
                          <SlideWrapperOverlay visible={dateVisible}>
                            <Text
                              size={16}
                              weight={600}
                              themecolor="white"
                              dangerouslySetInnerHTML={{ __html: date }}
                            />
                          </SlideWrapperOverlay>
                        </SlideInnerWrapper>
                      </SlideWrapper>
                    </SwiperSlide>
                  ),
                )}
              </Swiper>
              <StageIndicator>
                <StageIcons>
                  <Icon src={rectangleLight} size={20} alt="rectangle" />
                  <Icon src={rectangleDark} size={20} alt="rectangle" />
                </StageIcons>
                <SlideStageLine />
                <Text themecolor="secondary">
                  {
                    stages[activeStage].stages.find((item) => item.stageActive)
                      ?.stageTitle
                  }
                </Text>
              </StageIndicator>
            </SwiperWrapper>
          )}
        </GalleryContainer>
      </LazyLoad>
    </Section>
  )
}

export default Construction
